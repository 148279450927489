import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Typography, List, ListItem, FormControl, FormControlLabel, Radio, RadioGroup, Box, Checkbox, FormGroup } from "@mui/material";
import driverPic from "../../../assets/images/driver-pic.svg"
import { useDispatch } from 'react-redux';
import EastIcon from '@mui/icons-material/East';
import { fetchDrivers } from '../../../redux/slices/hyperdrive/driverAnalytics';
import { useSelector } from 'react-redux';
import Loader from 'common/Loader';
import { driverDataCustom } from '../../../redux/slices/modalSlice';
import noDataFound from 'assets/images/nothing-found.svg';
import { ThreeDot } from 'react-loading-indicators';

const MapDriveListDrawer = ({ sendDataToParent = () => { }, onValueChange, breakTask, idle, DriverIdType }) => {
    const dispatch = useDispatch();
    const { driverData } = useSelector(state => state.modal);
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedValueBreaks, setSelectedValueBreaks] = useState(driverData);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const [selectAll, setSelectAll] = useState(false);
    const [loadingFirst, setLoadingFirst] = useState(true);

    const loadMorePosts = useCallback(async (data) => {
        const fetchFunction = idle ? fetchDrivers : fetchDrivers;
        setLoading(true);
        try {
            const limit = 20;
            const skip = page * limit;
            const res = await dispatch(fetchFunction({
                skip,
                limit,
                ...(idle ? {} : { isActive: true })

            })).unwrap();

            const newPosts = res?.data;

            if (newPosts?.length === res?.total || [...posts, ...newPosts]?.length === res?.total) {
                setPosts((prevPosts) => [...prevPosts, ...newPosts]); // Append new drivers to the existing list
                setHasMore(false);
            }
            else {
                setPosts((prevPosts) => [...prevPosts, ...newPosts]); // Append new drivers to the existing list
            }
            // if (newPosts?.length === 0) {
            //     setHasMore(false);
            //     setLoadingFirst(false)
            // } else {
            //     setPosts((prevPosts) => [...prevPosts, ...newPosts]);
            //     setLoadingFirst(false)
            // }
        } catch (err) {
            setLoadingFirst(false)
            setLoading(false);
        } finally {
            setLoading(false);
            setLoadingFirst(false)
        }
    }, [dispatch, page]);

    useEffect(() => {
        if (hasMore) {
            loadMorePosts();
        }
    }, [loadMorePosts, hasMore]);

    const lastPostElementRef = useCallback(
        (node) => {
            if (loading || !hasMore) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const handleDriverDetails = () => {
        sendDataToParent(null);
    }

    const handleChangeTask = (event) => {
        const { name, value } = event.target;
        onValueChange(value);
        setSelectedValue(value);
        // handleDriverDetails()
    };
    const handleChangeTaskBreaks = (event, id) => {
        if (event.target.checked) {
            setSelectedValueBreaks(prev => [...prev, id]);
        } else {
            setSelectedValueBreaks(prev => prev.filter(value => value !== id));
        }
    };
    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);

        if (isChecked) {
            const allIds = posts.map(post => post._id);
            setSelectedValueBreaks(allIds);
        } else {
            setSelectedValueBreaks([]);
        }
    };

    useEffect(() => {
        if (DriverIdType) {
            onValueChange("");
        }
        else {
            onValueChange(selectedValueBreaks);
        }
        dispatch(driverDataCustom({
            driver: 'DRIVER_DATA',
            driverData: selectedValueBreaks
        }))
    }, [selectedValueBreaks])

    useEffect(() => {
        if (selectedValueBreaks.length === posts.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedValueBreaks, posts.length]);

    return (
        <div className="next-drawer">
            <Box p={2} className='d-flex align-item-center jc-s-btwn gap-2 drawer-head'>
                <div className='d-flex flex-row gap-3'>
                    <Typography variant='h6' className='text-14 text-white-700'>Driver List</Typography>
                </div>
                <div className='d-flex align-item-center gap-4 select-all-field'>
                    {
                        breakTask &&
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel control={<Checkbox checked={selectAll} onChange={handleSelectAllChange} />} label="Select all" labelPlacement="Select all" />
                            </FormGroup>
                        </FormControl>
                    }
                    <Typography style={{ cursor: "pointer" }} onClick={() => handleDriverDetails()} className='d-flex align-item-center gap-1 text-14 text-white-700'>
                        Close <EastIcon />
                    </Typography>
                </div>
            </Box>
            {
                loadingFirst ? <Box className='drawer-scroll-sec'>
                    <div className='d-flex align-item-center jc-center h-100'><Loader /></div></Box> :
                    <Box className='drawer-scroll-sec'>
                        <Box p={2} className='hd-drivers-listing'>
                            {
                                breakTask ?
                                    <List>
                                        {
                                            (posts?.length === 0 && !loading) ? (
                                                <ListItem>
                                                    <Box textAlign="center" className="w-100 no-data-found mt-5">
                                                        <img style={{ maxWidth: "120px" }} src={noDataFound} alt="No Results Found" />
                                                        <p className='text-12'><strong>No Drivers Found</strong></p>
                                                    </Box>
                                                </ListItem>
                                            ) :
                                                posts?.map((data, idx) => {
                                                    return (
                                                        <React.Fragment key={idx}>
                                                            <ListItem
                                                                ref={posts.length === idx + 1 ? lastPostElementRef : null}
                                                                className='d-flex align-item-center jc-s-btwn gap-2'
                                                            >
                                                                <div className='d-flex align-item-center flex-row gap-2'>
                                                                    <div className='d-flex radio-select'>
                                                                        <FormControl>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={selectedValueBreaks.includes(data?._id)}
                                                                                        onChange={(e) => handleChangeTaskBreaks(e, data?._id)}
                                                                                    />
                                                                                }
                                                                                label=""
                                                                                className='mr-0'
                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        {
                                                                            idle ?
                                                                                <img className='border-radius-50' src={data?.driverImage ? data?.driverImage : driverPic} alt='' width={28} height={28} />
                                                                                :
                                                                                <img className='border-radius-50' src={data?.driverImage ? data?.driverImage : driverPic} alt='' width={28} height={28} />
                                                                        }
                                                                    </div>
                                                                    <div className='d-flex flex-column'>
                                                                        <Typography variant='h6' className='text-14 text-white-700'>
                                                                            {idle ? data?.driverName : data?.driverName}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                                <Typography className='d-flex align-item-center gap-1 text-12 white-space-nowrap'>
                                                                    {idle ?
                                                                        <>
                                                                            <span className={data?.status ? 'dot-duty' : "dot-duty dot-off"}></span>
                                                                            <span className={data?.status ? 'driver-duty-txt' : 'driver-off-duty-txt'}>
                                                                                {data?.status ? "On Duty" : "Off Duty"}
                                                                            </span>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <span className={data?.status ? 'dot-duty' : "dot-duty dot-off"}></span>
                                                                            <span className={data?.status ? 'driver-duty-txt' : 'driver-off-duty-txt'}>
                                                                                {data?.status ? "On Duty" : "Off Duty"}
                                                                            </span>
                                                                        </>
                                                                    }
                                                                </Typography>
                                                            </ListItem>

                                                        </React.Fragment>
                                                    )
                                                })}
                                    </List> :
                                    <List>
                                        {posts?.length === 0 ? (
                                            <ListItem>
                                                <Box textAlign="center" className="w-100 no-data-found mt-5">
                                                    <img style={{ maxWidth: "120px" }} src={noDataFound} alt="No Results Found" />
                                                    <p className='text-12'><strong>No Drivers Found</strong></p>
                                                </Box>
                                            </ListItem>
                                        ) :
                                            posts?.map((data, idx) => (
                                                <React.Fragment key={idx}>
                                                    <ListItem ref={posts.length === idx + 1 ? lastPostElementRef : null}
                                                        className='d-flex align-item-center jc-s-btwn gap-2'  >
                                                        <div className='d-flex align-item-center flex-row gap-2'>
                                                            <div className='d-flex radio-select'>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        value={selectedValue}
                                                                        aria-labelledby=""
                                                                        defaultValue="offDuty"
                                                                        name="radio-buttons-group"
                                                                        className='radio-colors'
                                                                        onChange={handleChangeTask}
                                                                    >
                                                                        <FormControlLabel className='m-0' value={data?._id} control={<Radio />} />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <img className='border-radius-50' src={data?.driverImage ? data?.driverImage : driverPic} alt='' width={28} height={28} />
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <Typography variant='h6' className='text-14 text-white-700'>
                                                                    {data?.driverName}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <Typography className='d-flex align-item-center gap-1 text-12 white-space-nowrap'>
                                                            <span className={data?.status ? 'dot-duty' : "dot-duty dot-off"}></span>
                                                            <span className={data?.status ? 'driver-duty-txt' : 'driver-off-duty-txt'}>
                                                                {data?.status ? "On Duty" : "Off Duty"}
                                                            </span>
                                                        </Typography>

                                                    </ListItem>
                                                    {loading && <p>Loading...</p>}
                                                </React.Fragment>
                                            ))}
                                    </List>
                            }
                        </Box>
                        {loading && <p style={{ color: "#fff", fontSize: "0.9rem" ,textAlign:"center"}}>Load more.... <ThreeDot className="dot_loadmore" color="#0163FF" size="medium" text="" textColor="" /></p>}
                    </Box>
            }
        </div>
    );
};

export default MapDriveListDrawer;