import { useEffect, useState } from "react";
import { Box, Tab, Card, Paper, Grid, Typography, Divider } from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import driverPic from "../../assets/images/driver-pic.svg"
import { getDriverAnalyticsInfo } from "../../redux/slices/hyperdrive/driverAnalytics";
import { useDispatch } from "react-redux";
import SkeletonText from "common/component/SkeletonText";
import DriverTask from "./DriverTaskCheckinOut/DriverTask";
import DriverCheckInOut from "./DriverTaskCheckinOut/DriverCheckIn";
import { formatPhoneNumber } from "utilities/common";

const DriverDetails = ({ sendDataToParent = () => { }, driverId }) => {
    const [currentMainViewTab, setCurrentMainViewTab] = useState('driverInfo')
    const handleDriverDetails = () => {
        sendDataToParent(null);
    }
    const dispatch = useDispatch()
    const [driverDetails, setDriverDetails] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        // if (currentMainViewTab === "driverInfo") {
        dispatch(getDriverAnalyticsInfo({
            driverId
        })).unwrap().then(res => {
            setDriverDetails(res)
            setLoading(false)
        })
        // }
    }, [currentMainViewTab, driverId])

    return (
        <div className='driver-details-drawer'>
            <div className='d-flex align-item-center jc-s-btwn gap-2 driver-details-head'>
                <div className='d-flex flex-row align-item-center gap-2'>
                    <div className='d-flex'>
                        <img className='border-radius-50' src={driverPic} alt='' width={28} height={28} />
                    </div>
                    <div className='d-flex flex-column'>
                        <Typography variant='h6' className='text-14 text-white-700'>{driverDetails?.data?.driverInfo?.fleetName}</Typography>
                    </div>
                </div>
                <Typography style={{ cursor: "pointer" }} onClick={() => handleDriverDetails("")} className='d-flex align-item-center gap-1 text-14 text-white-700'>
                    Close <EastIcon />
                </Typography>
            </div>
            <TabContext value={currentMainViewTab}>
                <Box className="hd-tabs hd-drawer-tabs">
                    <TabList onChange={(e, value) => { setCurrentMainViewTab(value) }} aria-label="">
                        <Tab label="Driver Info" value="driverInfo" />
                        <Tab label="Tasks" value="tasks" />
                        <Tab label="Check In / Check Out" value="checkInOut" />
                    </TabList>
                </Box>
                <TabPanel value="driverInfo" className='driver-info-tabs'>
                    <Card component={Paper} style={{ flexDirection: "row" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography className='text-black-200 text-14 mb-1'>Driver ID</Typography>
                                {!loading ? <Typography variant='h5' className='text-18 text-white-700'>{driverDetails?.data?.driverInfo?.fleetDisplayId ? driverDetails?.data?.driverInfo?.fleetDisplayId : "-"}</Typography> : <SkeletonText />}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className='text-black-200 text-14 mb-1'>Phone</Typography>
                                {!loading ? <Typography variant='h5' className='text-18 text-white-700'>{driverDetails?.data?.driverInfo?.fleetPhone ? `${formatPhoneNumber(driverDetails?.data?.driverInfo?.fleetPhone)}`
                                    : "-"}</Typography> : <SkeletonText />}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className='text-black-200 text-14 mb-1'>Email</Typography>
                                {!loading ? <Typography variant='h5' className='text-18 text-white-700'>{driverDetails?.data?.driverInfo?.fleetEmail ? driverDetails?.data?.driverInfo?.fleetEmail : "-"}</Typography> : <SkeletonText />}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className='text-black-200 text-14 mb-1'>Assigned Region</Typography>
                                {!loading ? <Typography variant='h5' className='text-18 text-white-700'>{driverDetails?.data?.driverInfo?.regionData?.regionName ? driverDetails?.data?.driverInfo?.regionData?.regionName : "-"}</Typography> : <SkeletonText />}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className='text-black-200 text-14 mb-1'>Assigned Terminal</Typography>
                                {!loading ? <Typography variant='h5' className='text-18 text-white-700'>
                                    {driverDetails?.data?.driverInfo?.terminalData?.terminalName ? driverDetails?.data?.driverInfo?.terminalData?.terminalName : "-"}
                                </Typography> : <SkeletonText />}
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h5' className='text-18 text-white-700 mb-3'>Transportation</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <Typography className='text-black-200 text-14 mb-1'>Vehicle</Typography>
                                        {!loading ? <Typography variant='h5' className='text-18 text-white-700'>{driverDetails?.data?.driverInfo?.fleetTransportation?.name ? driverDetails?.data?.driverInfo?.fleetTransportation?.name : "-"}</Typography> : <SkeletonText />}
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography className='text-black-200 text-14 mb-1'>Model</Typography>
                                        {!loading ? <Typography variant='h5' className='text-18 text-white-700'> {driverDetails?.data?.driverInfo?.fleetVehicleDetails?.model ? driverDetails?.data?.driverInfo?.fleetVehicleDetails?.model : "-"}</Typography> : <SkeletonText />}
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography className='text-black-200 text-14 mb-1'>Year</Typography>
                                        {!loading ? <Typography variant='h5' className='text-18 text-white-700'>{driverDetails?.data?.driverInfo?.fleetVehicleDetails?.year ? driverDetails?.data?.driverInfo?.fleetVehicleDetails?.year : "-"} </Typography> : <SkeletonText />}
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography className='text-black-200 text-14 mb-1'>Color</Typography>
                                        {!loading ? <Typography variant='h5' className='text-18 text-white-700'> {driverDetails?.data?.driverInfo?.fleetVehicleDetails?.color ? driverDetails?.data?.driverInfo?.fleetVehicleDetails?.color : "-"}</Typography> : <SkeletonText />}
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography className='text-black-200 text-14 mb-1'>License Plate</Typography>
                                        {!loading ? <Typography variant='h5' className='text-18 text-white-700'> {driverDetails?.data?.driverInfo?.fleetVehicleDetails?.licensePlate ? driverDetails?.data?.driverInfo?.fleetVehicleDetails?.licensePlate : "-"}</Typography> : <SkeletonText />}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </TabPanel>
                <TabPanel value="tasks" className='driver-info-tabs'>
                    <Card component={Paper} style={{ flexDirection: "row" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DriverTask driverId={driverId} />
                            </Grid>
                        </Grid>
                    </Card>
                </TabPanel>
                <TabPanel value="checkInOut" className='driver-info-tabs'>
                    <Card component={Paper} style={{ flexDirection: "row" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DriverCheckInOut driverId={driverId} />
                            </Grid>
                        </Grid>
                    </Card>
                </TabPanel>
            </TabContext>
        </div>
    )
}

export default DriverDetails