import { useState, useEffect, useCallback } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";


// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { setMiniSidenav, setOpenConfigurator } from "./redux/slices/materialUISlice";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, setAccount } from "./redux/slices/authSlice";
import SignIn from "layouts/authentication/sign-in"
import { fetchBrands } from "./redux/slices/brand";
import { fetchInfoEffect, fetchCategories, fetchAllRegions, setAdminsMode } from "./redux/slices/common";
import CustomModal from "common/CommonModal";
import MDSnackbar from "components/MDSnackbar";
import { closeSnackbar } from "./redux/slices/common";
import { PublicRoutes } from "HOC/PublicRoute";
import { PrivateRoute } from "HOC/PrivateRoutes";
import { getData } from "utilities/common";
import { isEmptyObject } from "utilities/common";
import CommonDrawer from "common/CommonDrawer";
import ForgotPassword from "layouts/authentication/forgotPassword";
import ResetPassword from "layouts/authentication/resetPassword";
import PageNotFound from "layouts/401";
import { isLoggedIn } from "utilities/common";
import { onMessage } from "firebase/messaging";
import { messaging } from "firebaseInit";
import { fetchAllNotificationsCountsData, fetchNotificationCount, storeAddProductNotifications } from "./redux/slices/notifications";
import NotificationCard from "components/notifications/NotificationCard";
import { hyperfwolfAdminRoutes } from "routes";
import { HYPERWOLF } from "utilities/constants";
import { HYPERDRIVE } from "utilities/constants";
import { hyperfDriveAdminRoutes } from "routes";
import ResetHyperdrivePassword from "layouts/authentication/resetHyerdrivePassword";
import { Helmet } from "react-helmet";
import { capitalizeText } from "utilities/common";
export default function App() {
  const controller = useSelector((state) => state.materialUI);
  const { adminsMode = '' } = useSelector(state => state.common)
  const { isOpen = false, color = '', icon = '', content = '', bgWhite = false } = useSelector(state => state.common.snackbar);
  const { user = {} } = useSelector((state) => state.auth)
  const dispatch = useDispatch();

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      // setMiniSidenav(dispatch, false);
      dispatch(setMiniSidenav(false));
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      // setMiniSidenav(dispatch, true);,
      dispatch(setMiniSidenav(true));
      setOnMouseEnter(false);
    }
  };

  // <Helmet>
  // <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places`}></script>
  // </Helmet>

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => dispatch(setOpenConfigurator(!openConfigurator));

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const [mount, setMount] = useState(false)

  useEffect(() => {
    const adminsMode = getData('adminsMode')
    if (JSON.parse(adminsMode) === HYPERWOLF || JSON.parse(adminsMode) === HYPERDRIVE) return;
    dispatch(fetchBrands({ skip: 0, limit: 100, userType: 'admin' }))
    dispatch(fetchInfoEffect())
    dispatch(fetchAllRegions())
    dispatch(fetchCategories({ skip: 0, limit: 1000, userType: 'admin' }))

  }, [])

  let localUser = getData('login-user-info')
  if (localUser) {
    localUser = JSON.parse(localUser)
  }

  const getRoutes = (allRoutes) => {
    return allRoutes?.filter((route => route?.roles?.some((role => localUser?.userRoles?.includes(role))))).map((route) => {
      if (route.collapse) {
        // If the route has a 'collapse' property, it means it has nested routes.
        // Recursively call getRoutes for nested routes.
        return getRoutes(route.collapse);
      }
      if (route.route) {
        // If the route has a 'route' property, it means it's a leaf route.
        // Create a React Route with the specified path and associate it with a PrivateRoute.
        return (
          <Route
            exact
            path={route.route}
            element={
              <PrivateRoute>
                {route.component}
              </PrivateRoute>
            }
            key={route.key}
          />
        );
      }
      // If neither 'collapse' nor 'route' is present, return null.
      return null;
    });
  }

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );
  const { addProductNotifications = 0 } = useSelector(state => state.notifications)

  const notificationHandler = (payload) => {
    const { data } = payload
    const { body, approvalId, title, notificationType } = data || {}
    if (notificationType === 'approvals') {
      dispatch(storeAddProductNotifications({ ...data, order: body }))
      dispatch(fetchAllNotificationsCountsData({ notificationType: 'approvals' }))
    }
    console.log(payload, 'this is data')
  }

  useEffect(() => {
    dispatch(fetchNotificationCount({ notificationType: 'approvals' }))
      .unwrap()
      .then((res) => {
        // setCount(res.count);
      })
      .catch((error) => {
        console.error("Failed to fetch notification count:", error);
      });
  }, [addProductNotifications.length, addProductNotifications[0]?.readStatus]);

  const closeSnackbarMsg = () => {
    dispatch(closeSnackbar());
  }
  // useEffect(() => {
  //   const userData = JSON.parse(getData('login-user-info'))
  //   if (userData && !isEmptyObject(userData)) {
  //     dispatch(setAccount(userData))
  //   }
  // }, [])

  useEffect(() => {
    const userData = JSON.parse(getData('login-user-info'))
    if (userData && !isEmptyObject(userData)) {
      dispatch(getUserInfo(userData?._id))
    }
  }, [])


  useEffect(() => {
    setMount(true)
    return () => {
      setMount(false)
    }
  }, [user])


  useEffect(() => {
    if (!messaging) {
      console.warn('messaging is not initialized');
    }
    onMessage(messaging, (payload) => {
      notificationHandler(payload)
    })
  }, [mount])

  useEffect(() => {
    if (mount) {
      const adminsMode = getData('adminsMode')
      if (adminsMode) {
        dispatch(setAdminsMode(JSON.parse(adminsMode)))
      }
    }
  }, [mount])


  return (<>
    <Helmet>
      <title>{capitalizeText(adminsMode)} Admin</title>
    </Helmet>
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <MsalProvider instance={pca}> */}
        <CssBaseline />
        {layout === "dashboard" && pathname !== '/401' && pathname != "/" && !pathname.includes('hyperdrive') && isLoggedIn('userData')?.isAuthenticated && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Material Dashboard 2"
              routes={JSON.parse(getData('adminsMode')) === HYPERWOLF ? hyperfwolfAdminRoutes : routes}
            // onMouseEnter={handleOnMouseEnter}
            // onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {/* {layout === "dashboard" && pathname !== '/401' && pathname != "/" && pathname != "/hyperdrive" && isLoggedIn('userData')?.isAuthenticated && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Material Dashboard 2"
              routes={JSON.parse(getData('adminsMode')) === HYPERWOLF ? hyperfwolfAdminRoutes : routes}
            />
            <Configurator />
          </>
        )} */}
        {/* {layout === "vr" && <Configurator />} */}
        {/* <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes> */}
        <Routes>
          <Route path="/login" element={<PublicRoutes><SignIn /></PublicRoutes>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-hyperdrive-password" element={<ResetHyperdrivePassword />} />

          <Route path="/401" element={<PageNotFound />} />
          {/* <Route path="/signup" element={<PublicRoutes><Signup /></PublicRoutes>} /> */}

          {JSON.parse(getData('adminsMode')) === HYPERWOLF ? getRoutes(hyperfwolfAdminRoutes) : JSON.parse(getData('adminsMode')) === HYPERDRIVE ? getRoutes(hyperfDriveAdminRoutes) : getRoutes(routes)}

          <Route path="/" element={<Navigate to={JSON.parse(getData('adminsMode')) === HYPERWOLF ? '/manage-cannabinoids?cannabinoidTab=main_cannabinoid' : JSON.parse(getData('adminsMode')) === HYPERDRIVE ? '/hyperdrive/home?roleTab=map' : localUser?.userRoles?.includes('Super Admin') || localUser?.userRoles?.includes('Developer') ? '/dashboard' : localUser?.userRoles?.includes('Sales Associate') || localUser?.userRoles?.includes('Customer Support') ? '/orders?orderFilter=' : localUser?.userRoles?.includes('Sales Associate') || localUser?.userRoles?.includes('Developer') ? '/dashboard' : '/products'} />} />
          <Route path="*" element={<Navigate to={isLoggedIn('userData')?.isAuthenticated ? '/401' : '/login'} />} />
        </Routes>
        <NotificationCard fetchAllNotificationsCountsData={fetchAllNotificationsCountsData} style={{ paddingTop: '2px' }} />
        {/* <NotificationCount fetchAllNotificationsCountsData={fetchAllNotificationsCountsData}/> */}
        {/* <NotificationCount count={count}/> */}
        <NotificationCard fetchAllNotificationsCountsData={fetchAllNotificationsCountsData} style={{ paddingTop: '2px' }} />
        {/* <NotificationCount fetchAllNotificationsCountsData={fetchAllNotificationsCountsData}/> */}

        <CustomModal />
        <CommonDrawer />
        <MDSnackbar
          {...{
            color,
            icon,
            content,
            open: isOpen,
            onClose: closeSnackbarMsg,
            close: closeSnackbarMsg,
            bgWhite,
          }}
        />
        {/* </MsalProvider> */}
      </LocalizationProvider>

    </ThemeProvider>
  </>
  );
}
