import React, { useEffect } from 'react'
import { Card, Grid, Icon, Typography, Table, Box, IconButton, ListItem } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteFailureReason, getFailureReason } from '../../../../redux/slices/hyperdrive/setting';
import { useSelector } from 'react-redux';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../../redux/slices/modalSlice';
import { openSnackbar } from '../../../../redux/slices/common';
import Loader from 'common/Loader';
import noDataFound from 'assets/images/nothing-found.svg';

export default function FailureReason() {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams();
    const { mainFailureReasonData, loading } = useSelector(state => state?.commonHyperdrive)

    useEffect(() => {
        dispatch(getFailureReason())
    }, [])

    const deleteHandler = (reasonId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteFailureReason(reasonId)).unwrap().then((res) => {
            showError(res?.message, 'success')
            dispatch(closeCustomModalDialog())
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showError(err?.message, 'error')
        })
    }

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    return (
        <Card sx={{ py: 3, px: 4, mb: 4, borderRadius: 0 }}>
            <MDBox display="flex" alignItems="center" justifyContent="space-between" mb={3} mt={1}>
                <MDBox>
                    <Typography variant='h5' fontWeight="semibold" color="#F5F5FF">Failure Reason</Typography>
                </MDBox>
                <MDButton color="primary" size="medium" sx={{ minWidth: "120px" }} onClick={() => setSearchParams({ roleTab: 'Failure-Reason/add' })}>
                    <Icon>add</Icon>&nbsp;Add
                </MDButton>
            </MDBox>
            <Grid container alignItems="center" spacing={{ xs: 3, md: 3 }} mb={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {
                        loading ?
                            <Box className=''><Loader /></Box> :
                            <>
                                {
                                    mainFailureReasonData?.data?.length === 0 ?
                                        <ListItem>
                                            {
                                                !loading &&
                                                <Box textAlign="center" className="w-100 no-data-found mt-5">
                                                    <img style={{ maxWidth: "120px" }} src={noDataFound} alt="No Results Found" />
                                                    <p className='text-12' style={{ color: "#fff" }}><strong>No Results Found</strong></p>
                                                </Box>
                                            }
                                        </ListItem>
                                        :
                                        <Table className='hw-table'>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <Typography className='text-black-400 text-12 text-left'>Reason</Typography>
                                                    </th>
                                                    <th>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    mainFailureReasonData?.data?.map((FailureReasonData) => {
                                                        return (
                                                            <tr key={FailureReasonData?._id}>
                                                                <td>
                                                                    <Typography className='text-white-700 text-14'>{FailureReasonData?.reason}</Typography>
                                                                </td>
                                                                <td>
                                                                    <Box display="flex" alignItems="center" justifyContent="end" className="table-action-btn">
                                                                        <IconButton color='primary' size='small' variant="primary">
                                                                            <EditIcon width='16px' height='16px' onClick={() => setSearchParams({ roleTab: `Failure-Reason/edit/id=${FailureReasonData?._id}` })} />
                                                                        </IconButton>
                                                                        <IconButton color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: FailureReasonData?._id, deleteType: 'Failure Reason' }, modal: 'DELETE_MODAL' }))
                                                                            }}  >
                                                                            <DeleteIcon width='16px' height='16px' />
                                                                        </IconButton>
                                                                    </Box>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                }
                            </>
                    }
                </Grid>
            </Grid>
        </Card>
    )
}