import React from 'react'

export default function TaskAssignedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
      <path d="M6 8C8.20914 8 10 6.20914 10 4C10 1.79086 8.20914 0 6 0C3.79086 0 2 1.79086 2 4C2 6.20914 3.79086 8 6 8Z" fill="#0163FF"/>
      <path d="M6 9.33276C2.68781 9.33645 0.0036875 12.0206 0 15.3328C0 15.701 0.298469 15.9994 0.666656 15.9994H11.3333C11.7015 15.9994 12 15.701 12 15.3328C11.9963 12.0206 9.31219 9.33642 6 9.33276Z" fill="#0163FF"/>
    </svg>
  )
}
