import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteMember, fetchMembers } from '../../../redux/slices/member'
import { Avatar, Box, ButtonGroup, Card, CardActions, CardContent, Grid, IconButton, Stack, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { sortAndFilter } from 'components/tables/AgGridTable'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { formatDate } from 'utilities/common'
import { DeleteOutline, Edit } from '@mui/icons-material'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../redux/slices/modalSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Search from 'examples/Search'
import { useDebounce } from 'hooks/useDebounce'
import { deleteTerpenoid, fetchTerpenoids } from '../../../redux/slices/terpenoids'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import StatusText from 'common/component/StatusText'
import { openSnackbar } from '../../../redux/slices/common'
import MDBox from 'components/MDBox'
import ActiveIcon from 'common/customIcons/active'
import { fetchProductTerpenoids } from '../../../redux/slices/products'


export default function ProductTerpenoids({ terpenoidHandler = () => { } ,editTerpenoidHandler } ) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const { productId = "" } = useParams()
    const pageRef = useRef(0)
    const searchTermRef = useRef("")
    const mainGridApiRef = useRef('')
    const gridRefApi = useRef(null)
    const [searchTerm, setSearchTerm] = useState("")


    const handleSearch = useDebounce((term) => {
        // Perform search operation with the debounced term
        pageRef.current = 0
        resetTable()
        // mainGridApiRef.current.destroy()
        // getRows(gridRefApi.current)
    }, 500);

    const { isLoading = false, terpenoidsList = [] } = useSelector(state => state.terpenoids)
    const { isFetching = false, productTerpenoids = {} } = useSelector(state => state.products)


    const deleteHandler = (terpeneId) => {
        dispatch(setModalLoader(true))
        dispatch(deleteTerpenoid({terpeneId,productId})).unwrap().then((res) => {
            showError(res?.message, 'success')
            dispatch(closeCustomModalDialog())
            pageRef.current = 0
            resetTable()
        }).catch((err) => {
            dispatch(setModalLoader(false))
            showError(err?.message, 'error')
        })
    }

    const showError = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }


    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Icon',
            field: 'terpeneImage',
            key: "fullName",
            suppressMenu: false,
            cellRenderer: (props) => {
                if (!props?.data?.terpeneImage) return "-"
                return <img width={100} height={100} style={{ objectFit: 'cover', borderRadius: '10px', display: 'block' }} src={props?.value} />
            },
            minWidth: 240,
            maxWidth: 240
        },
        {
            headerName: 'Name',
            field: 'terpeneName',
            suppressMenu: false,
            cellRenderer: (props) => {
                return <Box sx={{ backgroundColor: "#F2F3F7", borderRadius: "5px", width: "100%", p: 1.5, whiteSpace: "normal" }}>
                    <Typography variant="caption" display="block" fontWeight="medium" color="secondary">{props?.data?.terpeneName}</Typography>
                </Box>
            },
        },
        {
            headerName: 'Cannabinoid Value',
            field: 'terpeneValue',
            suppressMenu: false,
            cellRenderer: (props) => {
                return <Box sx={{ backgroundColor: "#F2F3F7", borderRadius: "5px", width: "100%", p: 1.5, whiteSpace: "normal" }}>
                    <Typography variant="caption" display="block" fontWeight="medium" color="secondary">{props?.data?.terpeneValue}</Typography>
                </Box>
            },
        },


    ]);

    const getRows = async (params) => {
        gridRefApi.current = params;
        dispatch(fetchProductTerpenoids({ filters: { limit: 100, skip: pageRef.current, searchTerm: searchTermRef.current }, productId })).unwrap().then(res => {
            const { terpenoids, total = 0 } = res
            const terpenoidsArray = terpenoids?.map((item => {
                const date = new Date(item?.createdDate)
                return { ...item, status: item?.status ? "Active" : "Inactive", joinedDate: formatDate(date), id: item.terpeneId, address: `${item?.deliveryAddress?.address} ${item?.deliveryAddress?.city} ${item?.deliveryAddress?.state} ${item?.deliveryAddress?.zipcode}` }
            }))
            // const dataAfterSortingAndFiltering = sortAndFilter(
            //     terpenoidsArray,
            //     params.sortModel,
            //     params.filterModel
            // );
            // const rowsThisPage = dataAfterSortingAndFiltering.slice(
            //     params.startRow,
            //     params.endRow
            // );
            // let lastRow = -1;
            // if (total || terpenoids?.length <= params.endRow) {
            //     lastRow = dataAfterSortingAndFiltering.length;
            // }
            params.successCallback([...terpenoidsArray], total);
            pageRef.current = pageRef.current + 20
        }).catch(() => { })
    }

    const resetTable = () => {
        const dataSource = {
            getRows
        }
        mainGridApiRef.current.ensureIndexVisible(0,null);
        mainGridApiRef.current.setGridOption('datasource', dataSource);
    }

    const onRowClicked = (row) => {
        navigate(`/terpenoids/detail/${row?.data.terpeneId}`)
    }

    const onEditClicked = (row) => {
        editTerpenoidHandler(row?.data)
        // navigate(`/terpenoids/${row?.data._id}`)
    }

    const searchHandler = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        searchTermRef.current = value
        handleSearch(value);

        // Debounce the search callback
    };
    const isDetailPage = useMemo(() => {
        return pathname.includes('details')
    }, [pathname])

    useEffect(() => {
        if (!isDetailPage) {
            setColumnDefs([...columnDefs, {
                headerName: 'Action',
                cellRenderer: (props) => {
                    return <Box>
                        <IconButton onClick={() => onEditClicked(props)} color='primary' size='small' variant="primary">
                        <EditIcon width='16px' height='16px' />
                    </IconButton>
                        <IconButton onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?._id }, modal: 'DELETE_MODAL' }))} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
                            <DeleteIcon width='16px' height='16px' />
                        </IconButton>
                    </Box>
                },
                cellStyle: { textAlign: 'right', justifyContent: 'end' },
                headerClass: 'right-aligned-cell-header',
                minWidth: 120,
                maxWidth: 120
            },])
        }
    }, [isDetailPage])

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (isFetching) {
                mainGridApiRef.current.showLoadingOverlay()
            } else if (productTerpenoids?.total == 0 || (productTerpenoids?.terpenoids?.length === 0 && !productTerpenoids?.total )) {
                mainGridApiRef.current.showNoRowsOverlay()
            }
            else {
                mainGridApiRef.current.hideOverlay()
            }
        }

    }, [productTerpenoids, isFetching])


    return (
        <>
            <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={4}>
                <Grid item xs={12} sm={4}>
                    <Typography variant='h4'>Cannabinoids</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                        {/* <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} /> */}
                        {
                            !isDetailPage && <MDButton
                                startIcon={<AddRoundedIcon fontSize='large' />}
                                variant="contained" color="primary"
                                onClick={terpenoidHandler}

                            >
                                Select Cannabinoid
                            </MDButton>
                        }

                    </Stack>
                </Grid>
            </Grid>

            <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={isLoading || isFetching}
                gridOptions={{
                    rowHeight: 132,
                    // rowStyle: { paddingTop: "10px", paddingBottom: "10px" },
                }}
            />
        </>
    )
}
