import { useState } from "react";

import Grid from "@mui/material/Grid";

import MDButton from "components/MDButton";

import React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Images
import { Box, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { resetHyperDriverPassword } from "../../../redux/slices/authSlice";
import { openSnackbar } from "../../../redux/slices/common";
import hyperwolfLogo from "assets/images/hyperwolf.svg";
import authImg from "assets/images/reset-password.jpg";
import colors from "assets/theme/base/colors";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { isEmptyObject } from "utilities/common";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
function extractTokenFromURL(url) {
  const params = new URLSearchParams(url);
  return params.get('token');
}

function validatePasswords(passwordObj) {
  const errors = {};

  // Check if password field is empty
  if (!passwordObj.password) {
    errors.password = "Password is required";
  } else {
    // Check password strength
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (!passwordRegex.test(passwordObj.password)) {
      errors.password = "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character";
    }
  }

  // Check if confirmPassword field is empty
  if (!passwordObj.confirmPassword) {
    errors.confirmPassword = "Confirm password is required";
  } else if (passwordObj.password !== passwordObj.confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }

  // Return errors object if there are any errors, otherwise return null
  return Object.keys(errors).length ? { error: errors } : {};
}



export default function ResetHyperdrivePassword() {
  const { primary } = colors;

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const location = useLocation()
  const navigate = useNavigate()
  const { loading = false } = useSelector(state => state.auth)
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({})
  const [errors, setErrors] = useState({})
  const [showResetSuccess, setResetSuccess] = useState(false)

  const handleResetPasword = async () => {
    const { error } = validatePasswords(userData)
    if (!isEmptyObject(error)) {
      setErrors({ ...error })
      return;
    }
    setErrors({})
    const payload = {
      password: userData?.password,
      token: extractTokenFromURL(location?.search)
    }
    console.log("payload", payload)
    dispatch(resetHyperDriverPassword(payload)).unwrap().then((res) => {
      // navigate('/login')
      // showError(res?.message, 'success')
      setResetSuccess(true)
    }).catch((err) => {
      showError(err?.message, 'error')
    })
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  const onChangeHandler = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid className="auth-pg reset-password" alignItems="center" container>
      <Grid item xs={12} md={6} p={{ lg: 2.5 }}>
        <img src={authImg} alt="HYPERWOLF" className="auth-banner-img" />
      </Grid>

      <Grid item xs={12} md={6} p={{ md: 2.5 }} pt={{ lg: 2.5 }} mt={{ xs: -4, lg: 0 }}>
        <Stack textAlign={{ xs: "left", sm: "center" }} direction="column" alignItems={{ xs: "left", sm: "center" }} justifyContent="center" maxWidth={{ lg: "500px" }} mx="auto" height="100%" px={{ xs: 2, sm: 6 }} py={{ xs: 4, sm: 12 }} borderRadius={{ md: 8 }} sx={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }} style={{ backgroundColor: "#fff" }}>
          <Link className="logo" to='/login'>
            <img src={hyperwolfLogo} alt="HYPERWOLF" height="32px" />
          </Link>
          {showResetSuccess ? <Stack mt={5} direction='column' justifyContent='center' alignItems='center'>
            <Stack direction='row' mb={2} gap={1} alignItems='center'>
              <Typography fontSize={14}>Password Reset Successfully</Typography>
              <CheckCircleIcon color='success' />
            </Stack>
            <Typography fontSize={14}>You can login in with password in the Hyperdrive app.</Typography>
          </Stack>
            : <>


              <Box mt={{ xs: 0, sm: 6 }} mb={4}>
                <Typography variant="h2" fontWeight="semibold">Reset Password</Typography>
                {/* <Typography variant="subtitle2" fontWeight="regular" mt={1} color="#696D70">Lorem ipsum dolor consectetur adipiscing elit.</Typography> */}
              </Box>

              <TextField sx={{ mb: 1.5 }} error={Boolean(errors?.password)} helperText={errors?.password} fullWidth label="New Password" onChange={onChangeHandler} type={showPassword ? 'text' : 'password'} name="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="center">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="center"
                        size="small"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField error={Boolean(errors?.confirmPassword)} helperText={errors?.confirmPassword} fullWidth label="Confirm Password" onChange={onChangeHandler} type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="center">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="center"
                        size="small"
                        onClick={() => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <MDButton onClick={handleResetPasword} sx={{ mt: 3.5, minWidth: "100%", py: 1.75 }} variant="contained" color="primary">Submit</MDButton>
            </>}




        </Stack>
      </Grid>
    </Grid>
  )
}
