import React from 'react'

export default function TaskCreatedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_10798_6058)">
        <path d="M14.3332 9.41267L12.0112 8L14.3332 6.58733C14.5747 6.44266 14.7851 6.25133 14.9519 6.02451C15.1187 5.79769 15.2387 5.53992 15.3048 5.26623C15.371 4.99255 15.382 4.70843 15.3371 4.43046C15.2923 4.15249 15.1926 3.88622 15.0438 3.6472C14.895 3.40818 14.7 3.20118 14.4704 3.03829C14.2407 2.8754 13.9809 2.75987 13.7061 2.69845C13.4314 2.63702 13.1471 2.63093 12.8699 2.68053C12.5928 2.73013 12.3283 2.83443 12.0919 2.98733L9.99985 4.32667V2C9.99985 1.46957 9.78914 0.960859 9.41407 0.585786C9.03899 0.210714 8.53028 0 7.99985 0C7.46942 0 6.96071 0.210714 6.58564 0.585786C6.21057 0.960859 5.99985 1.46957 5.99985 2V4.392L3.90785 2.98733C3.43077 2.70158 2.86053 2.61467 2.31999 2.74532C1.77944 2.87596 1.31184 3.21371 1.01792 3.6858C0.723996 4.15789 0.627277 4.72655 0.748594 5.26926C0.869912 5.81198 1.19956 6.28533 1.66652 6.58733L3.98852 8L1.66652 9.41267C1.19956 9.71467 0.869912 10.188 0.748594 10.7307C0.627277 11.2735 0.723996 11.8421 1.01792 12.3142C1.31184 12.7863 1.77944 13.124 2.31999 13.2547C2.86053 13.3853 3.43077 13.2984 3.90785 13.0127L5.99985 11.654V14C5.99985 14.5304 6.21057 15.0391 6.58564 15.4142C6.96071 15.7893 7.46942 16 7.99985 16C8.53028 16 9.03899 15.7893 9.41407 15.4142C9.78914 15.0391 9.99985 14.5304 9.99985 14V11.6133L12.0919 13.0133C12.5689 13.2991 13.1392 13.386 13.6797 13.2554C14.2203 13.1247 14.6879 12.787 14.9818 12.3149C15.2757 11.8428 15.3724 11.2741 15.2511 10.7314C15.1298 10.1887 14.8001 9.71534 14.3332 9.41333V9.41267Z" fill="#5B5B65"/>
      </g>
      <defs>
        <clipPath id="clip0_10798_6058">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
