import { Typography, Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import EastIcon from '@mui/icons-material/East';
import NearMeIcon from '@mui/icons-material/NearMe';
import { fetchRoutesDetails } from '../../../redux/slices/hyperdrive/driverAnalytics';
import noDataFound from 'assets/images/nothing-found.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import CalculateMap from '../calculateMap';
import { separateWords } from 'utilities/common';
import { formatTimestampToDate24Hours } from 'utilities/common';

const TaskRoute = ({ sendDataToParent = () => { }, taskId, taskAddress }) => {
    const dispatch = useDispatch()
    const { driverRoutesDetails = {} } = useSelector(state => state.driverAnalyticsHyperDrive)
    const { taskDetails } = useSelector(state => state?.drivers)
    const [dataSource, setDataSource] = useState([])
    const handleDriverDetails = () => {
        sendDataToParent(null);
    }

    const fetch = async (taskId) => {
        try {
            const res = await dispatch(fetchRoutesDetails(taskId)).unwrap().then(res => {
                console.log("routeMap details resssssss", res?.data);
                setDataSource(res?.data)
            }).catch(err => {
            });
        } catch (err) {
            console.error("Response Error:", err);
        }
    }
    console.log("routeMap details", dataSource);
    useEffect(() => {
        fetch(taskId)
    }, [taskId])

    return (
        <div className="next-drawer">
            <Box p={2} className='d-flex align-item-center jc-s-btwn gap-2 drawer-head'>
                <div className='d-flex flex-row gap-3'>
                    <Typography variant='h6' className='text-14 text-white-700'>{separateWords(taskDetails?.data?.taskType)}</Typography>
                    <Typography className='text-14 text-black-400'> {taskDetails?.data?.taskDisplayId}</Typography>
                </div>
                <Typography style={{ cursor: "pointer" }} onClick={() => handleDriverDetails()} className='d-flex align-item-center gap-1 text-14 text-white-700'>
                    Close <EastIcon />
                </Typography>
            </Box>
            <Box className='drawer-scroll-sec'>
                <Box p={2}>
                    <div className='d-flex align-item-top gap-3'>
                        <NearMeIcon style={{ color: '#0163FF', marginTop: "5px" }} />
                        <div className='d-flex align-item-top flex-column'>
                            <Typography className='text-14 text-white-700' fontWeight="semibold">Driver Location at</Typography>
                            <Typography className='text-20 text-black-400' fontWeight="semibold" mb={1}>{taskDetails?.data?.actualTaskStartTime ? formatTimestampToDate24Hours(taskDetails?.data?.actualTaskStartTime) : ""}</Typography>
                            <Typography className='text-14 text-white-700' fontWeight="semibold">{taskDetails?.data?.address?.latitude} , {taskDetails?.data?.address?.longitude}  at {taskDetails?.data?.actualDistance ? taskDetails?.data?.actualDistance.toFixed(4) : "0"}  miles elevation</Typography>
                        </div>
                    </div>
                    {
                        taskDetails?.data?.streetView && <div className='street-map-view mt-4'>
                            <img src={taskDetails?.data?.streetView ? taskDetails?.data?.streetView : noDataFound} alt="" />
                        </div>
                    }
                    {/* <CalculateMap route={""} routeMap={driverRoutesDetails?.data} taskAddress={taskAddress} showImg={true} /> */}
                    <CalculateMap route={""} routeMap={dataSource} taskAddress={taskAddress} showImg={true} />

                </Box>
            </Box>
        </div>
    )
}

export default TaskRoute