import { isEmptyObject } from "utilities/common";

export function breakValidation(fields) {

const errors ={}

if (!fields.breakName) {
    errors.breakName = "Break name is required"
}

if (!fields.timezone) {
    errors.timezone = "Timezone is required"
}

if (!fields.fromTime) {
    errors.fromTime = "From Time is required"
}

if (!fields.toTime) {
    errors.toTime = "To time is required"
}

// if (!fields.sendBreakAt) {
//     errors.sendBreakAt = "Send Break At is required"
// }

return { errors, isValid: isEmptyObject(errors) };
}