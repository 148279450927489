
// @mui material components
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import StatsAnalyzeChart from "components/hyperdrive/analyze/StatsAnalyzeChart";
import CompletedTaskChart from "components/hyperdrive/analyze/CompletedTaskChart";
import { useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { fetchAnalyzeData, fetchAnalyzeGraphData } from "../../../redux/slices/hyperdrive/analyze";
import { useSelector } from "react-redux";
import { subDays, format } from 'date-fns';
import { ThreeDot } from "react-loading-indicators";

function Analyze() {
  const { analyzeData = {}, analyzeGraphData = {}, loading: loading = false } = useSelector(state => state.analyze)
  const dispatch = useDispatch()
  const [completedTaskGraphFilter, setCompletedTaskGraphFilter] = useState({
    groupBy: 'last24hour'
  })
  const [dataWithFilter, setDataWithFilter] = useState(null)

  const changeFilterHandler = (value) => {
    setCompletedTaskGraphFilter({ ...completedTaskGraphFilter, groupBy: value })
  }

  const meomizeTaskStats = useMemo(() => {
    let data = {};
    let completedTasks = {
      failed: analyzeData?.completedTasks?.failed,
      succeeded: analyzeData?.completedTasks?.succeeded,
      total: analyzeData?.completedTasks?.total,
      taskChart: {
        datasets: [
          {
            data: [analyzeData?.completedTasks?.succeeded, analyzeData?.completedTasks?.failed],
            backgroundColor: [
              "#60EDEF",
              "#3A49F9",
            ],
            barPercentage: 0.4,
            categoryPercentage: 0.5,
            borderWidth: 0,
            cutout: "90%",
          },
        ],
      },
      tasksPerHour: analyzeData?.completedTasks?.tasksPerHour
    }
    let delayedTask = {
      onTime: analyzeData?.delayedTask?.onTime,
      delayedLessThan10: analyzeData?.delayedTask?.delayedLessThan10,
      delayed10To60: analyzeData?.delayedTask?.delayed10To60,
      delayedMoreThan60: analyzeData?.delayedTask?.delayedMoreThan60,
      totalDelayedOrders: analyzeData?.delayedTask?.totalDelayedOrders,
      averageDelayTimePerTask: analyzeData?.delayedTask?.averageDelayTimePerTask,
      taskChart: {
        datasets: [
          {
            data: [analyzeData?.delayedTask?.onTime, analyzeData?.delayedTask?.delayedLessThan10, analyzeData?.delayedTask?.delayed10To60, analyzeData?.delayedTask?.delayedMoreThan60], // The values for each segment
            backgroundColor: [
              "#60EDEF",
              "#3A49F9",
              "#FC7D1A",
              "#C444FF",
            ],
            barPercentage: 0.4,
            categoryPercentage: 0.5,
            borderWidth: 0,
            cutout: "90%",
          },
        ],
      },
    }
    let tasksByServiceTime = {
      onTime: analyzeData?.tasksByServiceTime?.onTime,
      delayedLessThan10: analyzeData?.tasksByServiceTime?.delayedLessThan10,
      delayed10To20: analyzeData?.tasksByServiceTime?.delayed10To20,
      delayedMoreThan30: analyzeData?.tasksByServiceTime?.delayedMoreThan30,
      totalDelayedOrders: analyzeData?.tasksByServiceTime?.totalDelayedOrders,
      serviceTimePerTask: analyzeData?.tasksByServiceTime?.serviceTimePerTask,
      taskChart: {
        datasets: [
          {
            data: [analyzeData?.tasksByServiceTime?.onTime, analyzeData?.tasksByServiceTime?.delayedLessThan10, analyzeData?.tasksByServiceTime?.delayed10To20, analyzeData?.tasksByServiceTime?.delayedMoreThan30], // The values for each segment
            backgroundColor: [
              "#60EDEF",
              "#3A49F9",
              "#FC7D1A",
              "#C444FF",
            ],
            barPercentage: 0.4,
            categoryPercentage: 0.5,
            borderWidth: 0,
            cutout: "90%",
          },
        ],
      },
    }

    data = {
      completedTasks,
      delayedTask,
      tasksByServiceTime,
      averageMiles: {
        averageOfMilesPerTask: analyzeData?.averageOfMilesPerTask,
      }
    }
    return data;
  }, [analyzeData])

  const daysLast30Days = Array.from({ length: 30 }, (_, i) =>
    format(subDays(new Date(), i), 'dd-MMM-yy')
  ).reverse();

  const completedOrdersData = useMemo(() => {
    if (!analyzeGraphData?.ordersCompleted) return;
    const ordersMap = new Map();

    analyzeGraphData?.ordersCompleted.forEach(order => {
      const day = format(new Date(order.date), 'dd-MMM-yy');
      ordersMap.set(day, order.completedOrders);
    });

    return daysLast30Days.map(day => ordersMap.get(day) || 0);
  }, [analyzeGraphData?.ordersCompleted]);

  const failedOrdersData = useMemo(() => {
    if (!analyzeGraphData?.ordersCompleted) return;
    const ordersMap = new Map();

    analyzeGraphData?.ordersCompleted.forEach(order => {
      const day = format(new Date(order.date), 'dd-MMM-yy');
      ordersMap.set(day, order.failedOrders);
    });

    return daysLast30Days.map(day => ordersMap.get(day) || 0);
  }, [analyzeGraphData?.ordersCompleted]);

  const data = useMemo(() => ({
    labels: daysLast30Days,
    datasets: [
      {
        label: 'Completed Orders',
        data: completedOrdersData,
        backgroundColor: '#06D7F6',
        borderColor: '#06D7F6',
        borderWidth: 1,
        barPercentage: 0.4,
        categoryPercentage: 0.5,
      },
      {
        label: 'Failed Orders',
        data: failedOrdersData,
        backgroundColor: '#4807EA',
        borderColor: '#4807EA',
        borderWidth: 1,
        barPercentage: 0.4,
        categoryPercentage: 0.5,
      }
    ]
  }), [daysLast30Days, completedOrdersData, failedOrdersData]);

  useEffect(() => {
  }, [failedOrdersData])

  const refreshStats = (filters = {}) => {
    const data = { ...completedTaskGraphFilter, ...filters }
    setDataWithFilter(data)
    dispatch(fetchAnalyzeData(filters))
    dispatch(fetchAnalyzeGraphData(data))
  }

  useEffect(() => {
    dispatch(fetchAnalyzeData())
  }, [])

  useEffect(() => {
    const data = { ...dataWithFilter, ...completedTaskGraphFilter }
    dispatch(fetchAnalyzeGraphData(data))
  }, [completedTaskGraphFilter])

  return (
    <Box p={2} className="pb-0 h-100">
      {
        loading ?   <div className='loading-Spinner'><ThreeDot color="#0163FF" size="medium" text="" textColor="" /></div>  :
          <Grid container spacing={3}>
            <Grid item lg={4}>
              <StatsAnalyzeChart taskStats={meomizeTaskStats} refreshStats={refreshStats} />
            </Grid>
            <Grid item lg={8}>
              <CompletedTaskChart chartData={data} completedTaskGraphFilter={completedTaskGraphFilter} changeFilterHandler={changeFilterHandler} />
            </Grid>
          </Grid>
      }
    </Box>
  );
}

export default Analyze;
