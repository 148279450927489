import React, { useState, useEffect, useCallback } from 'react'
import { Grid, Button, TextField, Box, Card, Typography } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux';
import { shopUpdateTime, shopOpenTiming } from '../../../redux/slices/hyperwolf/auth';
import CommonLayout from 'layouts/DashboardLayout';
import { openSnackbar } from '../../../redux/slices/common';
import { getPackageTime, getProcessTime, getServiceTime, updatePackageTime, updateProcessTime, updateServiceTime } from '../../../redux/slices/hyperwolf/settings';

const HyperwolfShopTiming = () => {
    const dispatch = useDispatch()

    const [updateData, setUpdateData] = useState({
        shopCloseTime: '',
        shopOpenTime: '',
        specailAddressOpenTime: ''
    })

    const { shopeTimeData = {} } = useSelector(state => state.hyperwolfAuth)
    const { serviceTime = {}, processTime ={}, packageTime={} } = useSelector(state => state.hyperwolfSettings) || {}
    console.log(useSelector(state =>state.hyperwolfSettings), 'HYSET')
    const { shopCloseTime = "00:00", shopOpenTime = "00:00", specailAddressOpenTime = "00:00" } = shopeTimeData


    const [formData, setFormData] = useState({
        serviceTime: 0,
        processTime: 0,
        packageTime: 0,
    })

    useEffect(() => {
        setFormData({
            serviceTime: serviceTime?.data?.servicetime || 0,
            processTime: processTime?.data?.processtime || 0,
            packageTime: packageTime?.data?.packagetime || 0,
        });
    }, [serviceTime, processTime, packageTime]);


    console.log(packageTime, ' uuuu')

    useEffect(() => { dispatch(shopOpenTiming()) }, [])
    
    useEffect(() => { 
        dispatch(getPackageTime())
        dispatch(getProcessTime());
        dispatch(getServiceTime());
 }, [dispatch])



    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(e.target, 'etarget')
        setFormData(prevData  =>  ({
            ...prevData,
            [name] : Number(value)
        }))
    }   

    const handleUpdateServiceTime = () => {
        console.log(formData.serviceTime, 'SERVICE TIMEVALUE' )
        dispatch(updateServiceTime({ servicetime: formData.serviceTime })).unwrap().then(res => {
            console.log(res.data, 'RES')
            showResponseMessage(res.data.message, 'success')
        })
        .catch(err => {
            showResponseMessage(err.response.message, 'error ');
        })
    }

    const handleUpdatePackageTime = () => {
        console.log(formData.packageTime, 'PACKAGE TIMEVALUE' )
        dispatch(updatePackageTime({ packagetime: formData.packageTime })).unwrap().then(res => {
            console.log(res.data, 'RES')
            showResponseMessage(res.data.message, 'success')
        })
        .catch(err => {
            showResponseMessage(err.response.message, 'error ');
        })
    }

    const handleUpdateProcessTime = () => {
            console.log(formData.processTime, 'PROCESS TIMEVALUE')
        dispatch(updateProcessTime({ processtime: formData.processTime })).unwrap().then(res => {
            console.log(res.data, 'RES')
            showResponseMessage(res.data.message, 'success')
        })
        .catch(err => {
            showResponseMessage(err.response.message, 'error ');
        })
    }


    const updateShoptime = () => {
        dispatch(shopUpdateTime({
            shopCloseTime: updateData.shopCloseTime ? reverseTimeFormat(updateData.shopCloseTime) : shopCloseTime,
            shopOpenTime: updateData.shopOpenTime ? reverseTimeFormat(updateData.shopOpenTime) : shopOpenTime,
            specailAddressOpenTime: updateData.specailAddressOpenTime ? reverseTimeFormat(updateData.specailAddressOpenTime) : specailAddressOpenTime,
        })).unwrap().then((res) => {
            showResponseMessage(res?.message, 'success')
            dispatch(shopOpenTiming())
        }).catch(err => {
            showResponseMessage(err?.message, 'error')
        })
    }


    const timeFormat = useCallback((a) => {
        if (Number(a) !== 0) {
            let hour = parseInt(a.substr(0, 2));
            let minute = parseInt(a.substr(2, 2));
            return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
        }
    }, [])

    const reverseTimeFormat = (timeStr) => {
        let result = timeStr?.replace(/:/g, '');
        return result
    }

    const changeTime = (e, type) => {
        setUpdateData({ ...updateData, [type]: e.target.value })
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000); 
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }


    return (
        <CommonLayout>
            <Box p={{ xs: 2, sm: 3, lg: 4 }}>                
                <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: "none", mb: 2 }}>
                    <Typography variant='h5' mb={3}>Manage Shop Timings</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={4}>
                            <form noValidate>
                                <TextField
                                    id="time"
                                    label="Set Open Time"
                                    type="time"
                                    value={updateData.shopOpenTime ? updateData.shopOpenTime : timeFormat(shopOpenTime?.toString())}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    onChange={(e) => changeTime(e, 'shopOpenTime')}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                            <form noValidate>
                                <TextField
                                    id="time"
                                    label=" Special Address Open Time"
                                    type="time"
                                    value={updateData.specailAddressOpenTime ? updateData.specailAddressOpenTime : timeFormat(specailAddressOpenTime?.toString())}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    onChange={(e) => changeTime(e, 'specailAddressOpenTime')}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                            <form noValidate>
                                <TextField
                                    id="time"
                                    label="Set Close Time"
                                    type="time"
                                    value={updateData.shopCloseTime ? updateData.shopCloseTime : timeFormat(shopCloseTime?.toString())}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    onChange={(e) => changeTime(e, 'shopCloseTime')}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Box textAlign='start' mt={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size='small'
                                    onClick={updateShoptime}
                                    style={{ minWidth: "190px", padding: "10px" }}
                                >
                                    Update Time
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
                    
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={4}>
                        <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: "none", mb: 2 }}>
                            <Typography variant='h5' mb={3}>Service Time</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            id="serviceTime"
                                            label="Service Time in minutes"
                                            type="number"
                                            name="serviceTime"
                                            value={formData.serviceTime}
                                            fullWidth
                                            onChange={handleChange}
                                        />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Box textAlign='start' mt={1}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size='small'
                                            onClick={() => handleUpdateServiceTime()}
                                            style={{ minWidth: "190px", padding: "10px" }}
                                        >
                                            Update Service Time
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={12} lg={4}>
                        <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: "none", mb: 2 }}>
                            <Typography variant='h5' mb={3}>Process Time</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                        id="processTime"
                                        label="Process Time in minutes"
                                        type="number"
                                        value={formData.processTime}
                                        name="processTime"
                                        fullWidth
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Box textAlign='start' mt={1}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size='small'
                                            onClick={() => handleUpdateProcessTime()}
                                            style={{ minWidth: "190px", padding: "10px" }}
                                        >
                                            Update Process Time
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    
                    <Grid item xs={12} md={12} lg={4}>
                        <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: "none", mb: 2 }}>
                            <Typography variant='h5' mb={3}>Package Time</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                        id="packageTime"
                                        label="Package Time in minutes"
                                        type="number"
                                        value={formData.packageTime}
                                        name="packageTime"
                                        fullWidth
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Box textAlign='start' mt={1}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size='small'
                                            onClick={() => handleUpdatePackageTime()}
                                            style={{ minWidth: "190px", padding: "10px" }}
                                        >
                                            Update Package Time
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </CommonLayout>
    )
}

export default HyperwolfShopTiming