
import React, { useRef, useEffect, useState } from 'react';
import H from '@here/maps-api-for-javascript';
import noDataFound from 'assets/images/nothing-found.svg';
import start_mark from "../../assets/images/icons/marker/start_mark.svg"
import end_mark from "../../assets/images/icons/marker/end_mark.svg"

const CalculateMap = ({ route, routeMap, taskAddress, showImg }) => {
  console.log("routeMap map,", routeMap);
  const mapRef = useRef(null); // Reference to the DOM element for the map
  const platform = useRef(null); // Reference to the HERE Maps platform
  const mapInstance = useRef(null); // Reference to the map instance

  // Map routeMap points to the format required by HERE Maps
  useEffect(() => {
    if (!routeMap || routeMap?.length === 0) {
      return;
    }
    const updatedPoints = routeMap?.map(point => ({
      lat: point.latitude,
      lng: point.longitude
    }));
    const apikey = process.env.REACT_APP_MAP_KEY; // API key for HERE Maps
    platform.current = new H.service.Platform({ apikey }); // Initialize the HERE Maps platform

    // Set up raster tile service with specified parameters
    const rasterTileService = platform.current.getRasterTileService({
      queryParams: {
        style: 'logistics.night',
        features: 'vehicle_restrictions:active_and_inactive,pois:disabled',
        size: 512
      }
    });

    // Create raster tile layer
    const rasterTileProvider = new H.service.rasterTile.Provider(rasterTileService);
    const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);

    // Initialize the map instance
    const defaultLayers = platform.current.createDefaultLayers();
    mapInstance.current = new H.Map(mapRef.current, rasterTileLayer, {
      zoom: 10,
      center: { lat: updatedPoints[0].lat, lng: updatedPoints[0].lng },
      pixelRatio: window.devicePixelRatio || 1
    });

    const ui = H.ui.UI.createDefault(mapInstance.current, defaultLayers);
    ui.removeControl('mapsettings'); // Remove the map settings control

    // Function to cluster waypoints into groups for routing
    const clusterWaypoints = (points) => {
      const maxClusterSize = 50;// Max size of each cluster
      const clusters = [];

      for (let i = 0; i < points.length; i += maxClusterSize) {
        clusters.push(points.slice(i, i + maxClusterSize));// Group points into clusters
      }
      return clusters;// Return the clustered points
    };

    // Cluster the updated points
    const clusters = clusterWaypoints(updatedPoints);

    // Function to handle the routing result
    const onResult = (result) => {
      if (result.routes.length) {
        const lineStrings = [];
        // Extract polyline from route sections
        result.routes[0].sections.forEach((section) => {
          lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
        });

        const multiLineString = new H.geo.MultiLineString(lineStrings);// Create MultiLineString
        const routeLine = new H.map.Polyline(multiLineString, {
          style: { strokeColor: '#FFE37E', lineWidth: 3 } // Style for the route line
        });
        const group = new H.map.Group(); // Create a group for map objects
        group.addObjects([routeLine]);// Add route line to the group

        // Function to create a custom icon for markers
        const createCustomIcon = (iconUrl) => {
          return new H.map.Icon(iconUrl, { size: { w: 24, h: 24 } });
        };

        // Create start and end markers
        const startMarker = new H.map.Marker(updatedPoints[0], { icon: createCustomIcon(start_mark) });
        const endMarker = new H.map.Marker(updatedPoints[updatedPoints.length - 1], { icon: createCustomIcon(end_mark) });

        group.addObjects([startMarker, endMarker]);// Add markers to the group

        // Add the group to the map and adjust the view
        mapInstance.current.addObject(group);
        mapInstance.current.getViewModel().setLookAtData({
          bounds: group.getBoundingBox(),// Adjust the view to fit the markers
        });
      }
    };
    // Initialize the HERE Routing Service
    const router = platform.current.getRoutingService(null, 8);

    // Calculate route for each cluster of waypoints
    clusters.forEach(cluster => {
      // destination: `${taskAddress?.latitude},${taskAddress?.longitude}`,
      console.log("routeMap cluster[0]", cluster[0]);

      const origin = cluster[0];
      const destination = cluster[cluster.length - 1];
      console.log("cluster[cluster.length - 1]", cluster[cluster.length - 1], taskAddress, `${destination.lat},${destination.lng}`);
      const routingParameters = {
        routingMode: 'fast',
        transportMode: 'car',
        origin: `${origin.lat},${origin.lng}`,
        // destination: `${destination.lat},${destination.lng}`,
        destination: `${taskAddress?.latitude},${taskAddress?.longitude}`,

        return: 'polyline',
        via: new H.service.Url.MultiValueQueryParameter(
          cluster.map(wp => `${wp.lat},${wp.lng}`)
        )
      };

      // Calculate the route and handle the result
      router.calculateRoute(routingParameters, onResult, (error) => {
        alert(error.message);
      });
    });

    // Add event handling for map interactions
    new H.mapevents.Behavior(new H.mapevents.MapEvents(mapInstance.current));
    window.addEventListener('resize', () => mapInstance.current.getViewPort().resize()); // Adjust map on resize

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', () => mapInstance.current.getViewPort().resize());
    };
  }, [routeMap]); // Effect runs when routeMap changes

  return (
    <>
      {
        routeMap?.length == 0 ?
          <div className="d-flex align-item-center flex-column gap-3 jc-center p-4">
            {!showImg && <>
              <p className='text-white-700'><strong> No Route Found</strong></p>
            </>}
          </div> :
          <>
            <div style={{ position: 'relative', width: '100%', height: '100%', borderRadius: '6px', overflow: 'hidden' }}>
              {(routeMap?.length === 0 || routeMap === undefined) ? "" : <div ref={mapRef} style={{ width: '100%', height: route === "detailRoute" ? '200px' : route === "taskRoute" ? '500px' : '300px' }}></div>}
            </div>
          </>
      }
    </>
  );
};

export default CalculateMap;

