import { CloseRounded } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from 'common/customIcons/search';
import MDButton from 'components/MDButton';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'hooks/useDebounce';
import { AgGridTable } from 'components/tables/AgGridTable';
import { useDispatch } from 'react-redux';
import { fetchDrivers, getDriverAnalyticsExport } from '../../../redux/slices/hyperdrive/driverAnalytics';
import { useSelector } from 'react-redux';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import StatusTask from 'common/component/StatusTask';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import { formatTimestampTo12HourTime } from 'utilities/common';

export default function DriverAnalytics() {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch()
    const pageRef = useRef(0)
    const searchTermRef = useRef("")
    const driverTermRef = useRef("")
    const mainGridApiRef = useRef('')
    const gridRefApi = useRef(null)
    const [csv, setCsv] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [applyData, setApplyData] = useState(false)
    const [formData, setFormData] = useState({ activeDriver: '' });
    const { driverList = {}, loading: loading = false } = useSelector(state => state.driverAnalyticsHyperDrive)

    const columnDefs = useMemo(() => [
        {
            headerName: 'Driver ID',
            field: 'driverIdSorting',
            suppressMenu: false,
            minWidth: 180,
            flex: 1,
            cellRenderer: (props) => (props?.data?.driverId ? props?.data?.driverId : "-"),
            cellStyle: { whiteSpace: "normal", lineHeight: "1.5" },
        },
        {
            headerName: 'Driver Name',
            field: 'driverNameSorting',
            suppressMenu: false,
            minWidth: 180,
            flex: 1,
            cellRenderer: (props) => (props?.data?.driverName ? props?.data?.driverName : "-"),
            cellStyle: { whiteSpace: "normal", lineHeight: "1.5" }
        },
        {
            headerName: 'Today Task',
            field: 'taskCount',
            suppressMenu: false,
            minWidth: 80,
            cellRenderer: (props) => (props?.value ? props?.value : "0"),
            sortable: false,
            flex: 1,
        },
        {
            headerName: 'Scheduled Tasks',
            field: 'scheduledTask',
            suppressMenu: false,
            cellRenderer: (props) => (props?.value ? props?.value : "0"),
            minWidth: 120,
            flex: 1,
            sortable: false
        },
        {
            headerName: 'Status',
            field: 'status',
            suppressMenu: false,
            minWidth: 120,
            flex: 1.5,
            cellStyle: { whiteSpace: "normal", lineHeight: "1.5" },
            cellRenderer: (props) => (
                <StatusTask value={props?.data?.status} />

            ),
            sortable: false
        },
        {
            headerName: 'Check In',
            field: 'checkIn',
            suppressMenu: false,
            minWidth: 80,
            cellRenderer: (props) => {
                // return props?.value ? `${formatTimestampTo12HourTime(props?.value)}` : "-";
                return props?.value || '-'
            },
            sortable: false
        },
        {
            headerName: 'Check Out',
            field: 'checkOut',
            suppressMenu: false,
            minWidth: 80,
            cellRenderer: (props) => {
                // return props?.value ? `${formatTimestampTo12HourTime(props?.value)}` : "-";
                return props?.value || '-'
            },
            sortable: false
        },
        {
            headerName: 'Drawer starting cash',
            field: 'startingCash',
            suppressMenu: false,
            minWidth: 80,
            cellRenderer: (props) => {
                return props?.value ? `$${props?.value}` : "$0";
            },
            sortable: false
        },
        {
            headerName: 'Drawer Total Cash',
            field: 'totalCash',
            suppressMenu: false,
            minWidth: 80,
            cellStyle: { textAlign: "right", justifyContent: "end" },
            headerClass: "right-aligned-cell-header",
            cellRenderer: (props) => {
                return props?.value ? `$${props?.value}` : "$0";
            },
            sortable: false
        },
    ], []);

    const getSortInfo = useCallback((data) => {
        const result = {};
        data.forEach(item => {
            console.log("filter in", item, data);
            if (item?.colId === 'driverNameSorting') {
                result[item.colId] = item.sort === "asc" ? 'asc' : "desc";
            } else if (item?.colId === 'driverIdSorting') {
                result[item.colId] = item.sort === "asc" ? 'asc' : "desc";
            }
        });
        return result;
    }, []);

    const getRows = useCallback(async (params, apply, driverStatus) => {
        gridRefApi.current = params;
        let filter = {};
        if (params?.sortModel.length) {
            filter = { ...getSortInfo(params?.sortModel) };
        }
        try {
            dispatch(fetchDrivers({
                skip:searchTermRef.current ? 0 : pageRef.current,
                limit: 20,
                search: searchTermRef.current,
                ...filter,
                status: apply && driverStatus ? driverStatus : ""
            })).unwrap().then(res => {
                const { data, total } = res;
                const driverListArray = data?.map(item => {
                    return { ...item, status: item?.status ? "onduty" : "offduty" };
                });
                params.successCallback([...driverListArray], total);
                pageRef.current = pageRef.current + 20;
            })
        }
        catch {
            params.successCallback([], 0);
        }
    }, [dispatch, getSortInfo]);

    const searchFn = useCallback((apply, driverStatus) => {
        const dataSource = {
            getRows: (params) => getRows(params, apply, driverStatus)  // Pass status to getRows
        };
        if (mainGridApiRef.current) {
            mainGridApiRef.current.ensureIndexVisible(0, null);
            mainGridApiRef.current.setGridOption('datasource', dataSource);
        }
    }, [getRows]);

    const onRowClicked = useCallback((row) => {
        setSearchParams({ roleTab: `driver_analytics/detailsDriver/Driver-Info/id=${row?.data._id}` });
    }, []);

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (loading) {
                mainGridApiRef.current.showLoadingOverlay();
            } else if (driverList?.total === 0) {
                mainGridApiRef.current.showNoRowsOverlay();
            } else {
                mainGridApiRef.current.hideOverlay();
            }
        }
    }, [driverList, loading]);

    const handleSearch = useDebounce((apply, driverStatus) => {
        pageRef.current = 0;
        searchFn(apply, driverStatus);
        handleExport("true")
    }, 500);

    const searchHandler = useCallback((searchValue) => {
        setSearchTerm(searchValue);
        searchTermRef.current = searchValue;
        handleSearch("apply", formData?.activeDriver);
    }, [handleSearch]);

    const clearAllHandler = useCallback(() => {
        driverTermRef.current = "";
        setFormData({ activeDriver: "" });
        searchFn();
        pageRef.current = 0;
        setApplyData(false)
        handleExport()
    }, [searchFn]);

    const parseCsv = (csv) => {
        return new Promise((resolve, reject) => {
            Papa.parse(csv, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    resolve(result.data);
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                    reject(error);
                }
            });
        });
    };

    const applyHandler = () => {
        pageRef.current = 0;
        searchFn("apply", formData?.activeDriver);
        setApplyData(true)
        handleExport("true")
    }

    const handleExport = async (apply) => {
        const data = { search: searchTermRef.current }
        if (applyData || apply) {
            data.status = formData?.activeDriver ? formData?.activeDriver : ""
        }
        try {
            const res = await dispatch(getDriverAnalyticsExport({ data })).unwrap()
            const parseData = await parseCsv(res)
            setCsv(parseData)
        } catch (error) {
            console.error('Error handling CSV export or parsing:', error);
        }
    }

    useEffect(() => {
        handleExport()
    }, [])

    return (
        <Box p={2} className="h-100 row-pointer">
            <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={0} mb={3}>
                <Grid item xs={12} sm={2}>
                    <Typography variant='h4' className='text-24' color='#ffffff'>Driver Analytics</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={1.5}>
                        <TextField autoComplete="off" className="custom-search-field dark-field" placeholder="Search" label=""
                            value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <IconButton size='small'>
                                        <SearchIcon width='18px' height='18px' />
                                    </IconButton>
                                </InputAdornment>,
                                endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                                    <IconButton onClick={() => searchHandler('')} size='small'>
                                        <CloseRounded />
                                    </IconButton>
                                </InputAdornment> : null
                            }}
                            style={{ width: "270px" }}
                        />
                        <MDButton onClick={() => { handleExport() }} style={{ textTransform: "none", minWidth: "180px", borderRadius: "30px", minHeight: "40px" }}
                            startIcon={<FileDownloadOutlinedIcon fontSize='large' />} variant="contained" color="primary">
                            <CSVLink data={csv} style={{ color: "#ffffff", textTransform: "none" }}>
                                Export as .CSV
                            </CSVLink>
                        </MDButton>
                    </Stack>
                </Grid>
            </Grid>
            <Box className="cus-filter-sec" mb={1}>
                <Grid className='filter-box' container alignItems="center" spacing={1.5}>
                    <Grid item xs={12} sm={12} md={12} xxl={12}>
                        <Box className="filter-price-unit">
                            <Stack direction='row' justifyContent='flex-end' width='100%' gap={2} >
                                <Grid item xs={12} sm={12} md={12} xxl={12}>
                                    <Box display="flex" alignItems="center" justifyContent='flex-end' gap={2} className="w-100">
                                        <Box className="filter-pro-status dark-field filter-min-w">
                                            <Stack direction='row' alignItems="center" gap={1}>
                                                <FormControl fullWidth sx={{ width: 300 }}>
                                                    <InputLabel>Driver Status</InputLabel>
                                                    <Select size='small' value={formData?.activeDriver} onChange={(e) => { setFormData({ activeDriver: e.target.value }) }} fullWidth className='form-select' name='status' label='Product Status' MenuProps={{ className: "cus-popover" }}>
                                                        <MenuItem value='true'>On Duty</MenuItem>
                                                        <MenuItem value='false'>Off Duty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                        </Box>
                                        <Box className="filter-btns">
                                            <Stack direction='row' alignItems="center" gap={1} className='w-100'>
                                                <Button className="filter-clear-all" onClick={clearAllHandler} size="small" variant="text" color="secondary" style={{ width: "100px" }}>
                                                    Clear All
                                                </Button>
                                                <MDButton className="filter-apply" onClick={() => applyHandler()} size="small" variant="contained" color="primary" style={{ width: "120px" }}>
                                                    Apply
                                                </MDButton>
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <div className="ag-theme-quartz-dark">
                <AgGridTable
                    col={columnDefs}
                    mainGridApiRef={mainGridApiRef}
                    getRows={getRows}
                    pageRef={pageRef}
                    isLoading={loading}
                    onRowClicked={onRowClicked}
                    style={{ height: "70vh" }}
                    gridOptions={{
                        rowHeight: 64,
                    }}
                    maxLimit={20}
                />
            </div>
        </Box>
    )
}