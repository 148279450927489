
import { useCallback, useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";


// Material Dashboard 2 React context
import {
  setMiniSidenav,
} from "../../redux/slices/materialUISlice";
import { useDispatch, useSelector } from "react-redux";
// import { accessPermissionConstants } from "../../utilities/constants";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  // const [controller, dispatch] = useMaterialUIController();
  const controller = useSelector((state) => state.materialUI);
  const [isOpen, setOpen] = useState({ id: -1, status: false });
  const { user = {}, userRoleAndPermissions = {} } = useSelector(state => state.auth)
  const { permissions = [] } = userRoleAndPermissions || {}

  const dispatch = useDispatch();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => dispatch(setMiniSidenav(true));


  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      dispatch(setMiniSidenav(!window.innerWidth >= 1200));
      // dispatch(setTransparentSidenav(window.innerWidth <= 1200 ? false : transparentSidenav));
      // dispatch(setWhiteSidenav(window.innerWidth <= 1200 ? false : whiteSidenav));
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    if (window.innerWidth <= 1200) {
      handleMiniSidenav();
    }

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const handleOpenCategory = (index) => {
    // window.scrollTo(10, 10);
    setOpen({
      id: index,
      status: isOpen?.id === index ? !isOpen?.status : true,
    });

  };

  if(collapseName==='Products'){
    console.log( collapse?.find((collapse => collapse?.key === collapseName)))
  }
  // const getScreenViewAccess = useCallback((key) => {
  //   let { permissions: permissionList } = permissions?.find((permission => permission?.title?.toLowerCase() === key?.toLowerCase())) || {}
  //   let access = {}
  //   permissionList?.forEach((accessItem => {
  //     access = {
  //       ...access,
  //       [accessItem.title]: accessItem?.status,
  //     }
  //   }))
  //   return key === 'orders' ? access[accessPermissionConstants.Hide_Order_View] : key === 'dashboard' ? access[accessPermissionConstants.Hide_Dashboard_View] : key === 'members' ? access[accessPermissionConstants.Hide_Member_View] : key === 'POS' ? access[accessPermissionConstants.Hide_POS_completely] : false
  //   // return access
  // }, [permissions])

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route, collapse, dropdown, roles, screen }, index) => {
    // const showAdmin = name?.toLowerCase() === 'admin' && !user?.isSuperAdmin
    let returnValue;
    // if(!roles?.some((role => user?.userRoles?.includes(role)))) {
    //   returnValue = null
    // }
    // if (showAdmin) {
    //   returnValue = null
    // }
    // if (getScreenViewAccess(screen)) {
    //   returnValue = null
    //   return returnValue;
    // }
    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
            handleOpenCategory={() => handleOpenCategory(index)}
            isOpen={isOpen}
          />
        </Link>

      ) : (
        // <NavLink key={key} to={route}>
        //   <SidenavCollapse name={name} icon={icon} active={key === collapseName} dropdown={dropdown} collapse={collapse} index={index} handleOpenCategory={() => handleOpenCategory(index)}
        //     isOpen={isOpen} />
        // </NavLink>
        


        name === 'Products'  && key!=='hyperwolf_products' ? <SidenavCollapse className="collapse-menu-item" setOpen={setOpen} screen={screen} name={name} icon={icon} 
        active={key === collapseName || collapse?.find((collapse => collapse?.key === collapseName))?.screen === screen} 
        dropdown={dropdown} collapse={collapse} index={index} handleOpenCategory={() => handleOpenCategory(index)}
          isOpen={isOpen} /> 
          
         :
          
        name === 'Manage Website' ? <SidenavCollapse className="collapse-menu-item" setOpen={setOpen} screen={screen} name={name} icon={icon}  
        active={key === collapseName || collapse?.find((collapse => collapse?.key === collapseName))?.screen === screen}
        dropdown={dropdown} collapse={collapse} index={index} handleOpenCategory={() => handleOpenCategory(index)}
        isOpen={isOpen} />
        
          : <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon}  setOpen={setOpen} active={key === collapseName} screen={screen} dropdown={dropdown} collapse={collapse} index={index} handleOpenCategory={() => handleOpenCategory(index)}
            isOpen={isOpen} />
        </NavLink>


      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      className="cus-sidebar"
    >
      <MDBox py={3} px={1}>
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          {/* <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography> */}
        </MDBox>

        {/* <MDBox component={NavLink} to="/"> */}
        {/* {brand && <MDBox component="img" src={brand} alt="Brand" width="5rem" />} */}
        {/* <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox> */}
        {/* <Image
            source={require('../../assets/images/hyperwolf.svg')}
          /> */}
        {/* <img src={hyperwolfLogo} alt="HYPERWOLF"/> */}
        {/* </MDBox> */}
      </MDBox>

      {/* <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      /> */}

      <List className="sidebar-nav" sx={{ my: 3.5 }}>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
