import React from 'react'

export default function StartIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11000_3866)">
        <path d="M3.5612 3.78027L2.3483 3.87398C2.0108 3.90007 1.71021 4.07695 1.52356 4.35927L0.124748 6.4749C-0.0168094 6.689 -0.0393132 6.95694 0.0645102 7.19165C0.168361 7.42637 0.381777 7.58994 0.635389 7.62923L1.74595 7.80122C2.00574 6.42256 2.62764 5.04729 3.5612 3.78027Z" fill="white"/>
        <path d="M6.19824 12.2537L6.37026 13.3643C6.40955 13.6179 6.57312 13.8313 6.80781 13.9351C6.90546 13.9783 7.00881 13.9997 7.11165 13.9997C7.25611 13.9997 7.39955 13.9576 7.52459 13.8749L9.64027 12.4761C9.9226 12.2894 10.0995 11.9888 10.1255 11.6514L10.2192 10.4385C8.95217 11.3721 7.57693 11.994 6.19824 12.2537Z" fill="white"/>
        <path d="M5.78053 11.4838C5.81908 11.4838 5.85785 11.4806 5.89655 11.4742C6.47415 11.3776 7.03086 11.2149 7.56193 11.002L2.99747 6.4375C2.78452 6.96854 2.62183 7.52525 2.52525 8.10289C2.48719 8.33058 2.56356 8.56253 2.72677 8.72577L5.27366 11.2727C5.40917 11.4081 5.59207 11.4838 5.78053 11.4838Z" fill="white"/>
        <path d="M12.8836 6.20706C14.0006 4.04764 14.042 1.76565 13.9836 0.695428C13.9636 0.328724 13.6712 0.0363669 13.3045 0.0163788C13.1302 0.00686323 12.9237 0 12.6909 0C11.4942 0 9.60066 0.18126 7.79287 1.11633C6.35619 1.85944 4.4723 3.49571 3.36084 5.64606C3.37396 5.65631 3.38679 5.66719 3.39887 5.67928L8.32071 10.6011C8.3328 10.6132 8.34365 10.626 8.35391 10.6391C10.5043 9.52764 12.1405 7.64375 12.8836 6.20706ZM8.14008 2.95966C8.93963 2.16011 10.2407 2.16003 11.0403 2.95966C11.4276 3.34699 11.6409 3.862 11.6409 4.40977C11.6409 4.95755 11.4276 5.47256 11.0403 5.85988C10.6405 6.25962 10.1153 6.45953 9.59019 6.45958C9.06495 6.45961 8.53992 6.25973 8.14008 5.85988C7.75273 5.47256 7.5394 4.95755 7.5394 4.40977C7.5394 3.862 7.75273 3.34699 8.14008 2.95966Z" fill="white"/>
        <path d="M8.72025 5.27963C9.2 5.75938 9.98066 5.75941 10.4604 5.27963C10.6928 5.04721 10.8208 4.73823 10.8208 4.40955C10.8208 4.08088 10.6928 3.7719 10.4604 3.5395C10.2206 3.29962 9.90544 3.17969 9.59033 3.17969C9.27525 3.17969 8.96014 3.29962 8.72028 3.5395C8.48789 3.7719 8.35986 4.08088 8.35986 4.40955C8.35986 4.73823 8.48786 5.04724 8.72025 5.27963Z" fill="white"/>
        <path d="M0.418471 11.5374C0.523443 11.5374 0.628416 11.4973 0.708478 11.4172L2.04758 10.0781C2.20776 9.91793 2.20776 9.65824 2.04758 9.49806C1.88743 9.33788 1.62772 9.33788 1.46754 9.49806L0.128436 10.8372C-0.0317441 10.9973 -0.0317441 11.257 0.128436 11.4172C0.208525 11.4973 0.313498 11.5374 0.418471 11.5374Z" fill="white"/>
        <path d="M3.27473 10.7256C3.11458 10.5654 2.85487 10.5654 2.69469 10.7256L0.120135 13.3002C-0.0400449 13.4603 -0.0400449 13.72 0.120135 13.8802C0.200225 13.9603 0.305197 14.0003 0.41017 14.0003C0.515143 14.0003 0.620115 13.9603 0.700178 13.8802L3.2747 11.3056C3.43491 11.1455 3.43491 10.8858 3.27473 10.7256Z" fill="white"/>
        <path d="M3.92186 11.9522L2.58278 13.2913C2.4226 13.4515 2.4226 13.7111 2.58278 13.8713C2.66287 13.9514 2.76784 13.9915 2.87279 13.9915C2.97773 13.9915 3.08273 13.9514 3.1628 13.8713L4.5019 12.5322C4.66208 12.372 4.66208 12.1123 4.5019 11.9522C4.34175 11.792 4.08204 11.792 3.92186 11.9522Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_11000_3866">
          <rect width="14" height="14" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
