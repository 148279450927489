import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getChecklistApi, getTransportAPI, updateCheckListStatusAPI, updateTransportStatusAPI } from '../../apis/hyperdrive/setting';
import { fetchCheckListDetailsApi, fetchDriversApi, fetchIdleDriversApi, fetchRegionListApi, fetchRoutesDetailsApi, getDriverAnalyticsExportApi, getDriverAnalyticsInfoApi } from '../../../redux/apis/hyperdrive/driverAnalytics';

const initialState = {
  driverAnalytics: [],
  loading: false,
  error: null,
  flavors: [],
  getdriverAnalytics: {}
};

export const fetchDrivers = createAsyncThunk(
  'promotion/fetchDrivers',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchDriversApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


export const fetchIdleDrivers = createAsyncThunk(
  'promotion/fetchIdleDrivers',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchIdleDriversApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const fetchRegionList = createAsyncThunk(
  'promotion/fetchRegionList',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchRegionListApi(data);
      console.log("regionListposts action",res);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


export const getDriverAnalyticsInfo = createAsyncThunk(
  'promotion/getDriverAnalyticsInfo',
  async ({ driverId, data }, { rejectWithValue }) => { 
    
    try {
      const res = await getDriverAnalyticsInfoApi(driverId,data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getDriverAnalyticsExport = createAsyncThunk(
  'promotion/getDriverAnalyticsExport',
  async ( {data} , { rejectWithValue }) => {   
    try {
      const res = await getDriverAnalyticsExportApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const fetchRoutesDetails = createAsyncThunk(
  'promotion/fetchRoutesDetails',
  async ( data , { rejectWithValue }) => {   
    try {
      const res = await fetchRoutesDetailsApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const fetchCheckListDetails = createAsyncThunk(
  'common/fetchCheckListDetails',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchCheckListDetailsApi(data);
      return res;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);

const commonDriverAnalyticsSlice = createSlice({
  name: 'driverHyperDrive',
  initialState,
  reducers: {
    setCommonDriverHyperdrive: (state, action) => {
      return { ...state, driverAnalytics: action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDrivers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDrivers.fulfilled, (state, action) => {
        state.loading = false;
        state.driverList = action.payload;
      })
      .addCase(fetchDrivers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      builder
      .addCase(getDriverAnalyticsInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDriverAnalyticsInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.driverDetails = action.payload;
      })
      .addCase(getDriverAnalyticsInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      builder
      .addCase(fetchRoutesDetails.pending, (state) => {
        state.taskloading = true;
        state.error = null;
      })
      .addCase(fetchRoutesDetails.fulfilled, (state, action) => {
        state.taskloading = false;
        state.driverRoutesDetails = action.payload;
      })
      .addCase(fetchRoutesDetails.rejected, (state, action) => {
        state.taskloading = false;
        state.error = action.payload;
      })

      builder
      .addCase(fetchRegionList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRegionList.fulfilled, (state, action) => {        
        state.loading = false;
        state.regionList = action.payload;
      })
      .addCase(fetchRegionList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      builder
      .addCase(fetchIdleDrivers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIdleDrivers.fulfilled, (state, action) => {        
        state.loading = false;
        state.idleDrivers = action.payload;
      })
      .addCase(fetchIdleDrivers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      builder
      .addCase(fetchCheckListDetails.pending, (state) => {
        state.detailLoading = true;
        state.error = null;
      })
      .addCase(fetchCheckListDetails.fulfilled, (state, action) => {        
        state.detailLoading = false;
        state.checkListDetails = action.payload;
      })
      .addCase(fetchCheckListDetails.rejected, (state, action) => {
        state.detailLoading = false;
        state.error = action.payload;
      })
      builder
      .addCase(getDriverAnalyticsExport.pending, (state) => {
        state.loadingExport = true;
        state.error = null;
      })
      .addCase(getDriverAnalyticsExport.fulfilled, (state, action) => {
        state.loadingExport = false;
        state.dataExport = action.payload;
      })
      .addCase(getDriverAnalyticsExport.rejected, (state, action) => {
        state.loadingExport = false;
        state.error = action.payload;
      })
  },
});

export const { driverAnalytics } = commonDriverAnalyticsSlice.actions;

export default commonDriverAnalyticsSlice.reducer;