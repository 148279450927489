import { Grid, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AgGridTable } from 'components/tables/AgGridTable';
import { fetchRouteExport, fetchRouteTable } from '../../../redux/slices/hyperdrive/drivers';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { formatTimestampToWithoutTime } from 'utilities/common';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MDButton from 'components/MDButton';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import { customApiSwitch, openCustomDrawer } from '../../../redux/slices/modalSlice';
import { formatTimestampToMapOld } from 'utilities/common';
import { formatTime } from 'utilities/common';
import { formatTimestampToDate24Hours } from 'utilities/common';

export const RouteAnalyticsTable = ({ routeFilter }) => {
    const dispatch = useDispatch()
    const mainGridApiRef = useRef('')
    const pageRef = useRef(0)
    const gridRefApi = useRef(null)
    const [csv, setCsv] = useState([])
    const { routeTable = {}, tableloading: tableloading = false } = useSelector(state => state.drivers)
    const { dataSwitch, routeData } = useSelector(state => state.modal)

    const getSortInfo = useCallback((data) => {
        const result = {};
        data.forEach(item => {
            if (item?.colId === 'driverNameSorting') {
                result[item.colId] = item.sort === "asc" ? 'asc' : "desc";
            } else if (item?.colId === 'createdDateSorting') {
                result[item.colId] = item.sort === "asc" ? 'asc' : "desc";
            }
            else {
                return false
            }
        });
        return result;
    }, []);

    const getRows = useCallback(async (params, filters) => {
        const start = filters?.startDate == undefined ? routeFilter?.startDate : filters?.startDate == null ? "" : formatTimestampToMapOld(filters?.startDate)
        const end = filters?.endDate == undefined ? routeFilter?.endDate : filters?.endDate == null ? "" : formatTimestampToMapOld(filters?.endDate)
        // console.log("routeFiltersssssssssssssss -----------startDate", start, "filtr------------", formatTimestampToMapOld(filters?.startDate), filters?.startDate, "routeee----------", routeFilter?.startDate);
        const today = new Date();
        const todayDate = formatTimestampToMapOld(today)
        gridRefApi.current = params;
        let filter = {};
        if (params?.sortModel.length) {
            filter = { ...getSortInfo(params?.sortModel) };
        }
        try {
            const res = await dispatch(fetchRouteTable({
                data: {
                    skip: pageRef.current,
                    limit: 20,
                    ...filter,
                    startDate: formatTimestampToMapOld(filters?.startDate) !== "Invalid Date" ? start : "",
                    endDate: formatTimestampToMapOld(filters?.endDate) !== "Invalid Date" ? end : "",
                    // startDate: ((filters?.startDate && filters?.endDate) && filters?.startDate) ? start : "",
                    // endDate: ((filters?.startDate && filters?.endDate) && filters?.endDate) ? end : "",
                    fleetName: filters?.driverName ? filters?.driverName : "",
                },
            })).unwrap();
            const { tasks } = res?.data;
            const { totalTasks } = res
            const driverListArray = tasks?.map((item) => ({
                ...item,
            }));
            params.successCallback([...driverListArray], totalTasks);
            pageRef.current += 20;
        } catch {
            params.successCallback([], 0);
        }
    }, [dispatch]);

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (tableloading) {
                mainGridApiRef.current.showLoadingOverlay();
            } else if (routeTable?.totalTasks === 0) {
                mainGridApiRef.current.showNoRowsOverlay();
            } else {
                mainGridApiRef.current.hideOverlay();
            }
        }
    }, [routeTable, tableloading]);

    useEffect(() => {
        if (routeFilter) {
            pageRef.current = 0
            searchFn(routeFilter)
        }
    }, [routeFilter])

    // console.log("routeFilter", routeFilter);

    useEffect(() => {
        if (routeData == "route_analytics" && routeFilter) {
            pageRef.current = 0
            searchFn(routeFilter)
            dispatch(customApiSwitch({
                dataSwitch: '',
                routeData: ""
            }))
        }
    }, [routeData])

    const searchFn = useCallback((routeFilter) => {
        pageRef.current = 0
        const dataSource = {
            getRows: (params) => getRows(params, routeFilter)  // Pass status to getRows
        };
        if (mainGridApiRef.current) {
            mainGridApiRef.current.ensureIndexVisible(0, null);
            mainGridApiRef.current.setGridOption('datasource', dataSource);
        }
    }, [getRows]);

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Task Id',
            field: 'taskDisplayId',
            minWidth: 120,
            sortable: false
        },
        {
            headerName: 'Transaction Number',
            field: 'transactionNumber',
            suppressMenu: false,
            minWidth: 180,
            flex: 1,
            cellStyle: { whiteSpace: "normal", lineHeight: "1.5" },
            cellRenderer: useCallback((props) => (
                <>
                    {props.value
                        ? props?.value
                        : "-"}
                </>
            ), []),
            sortable: false
        },
        {
            headerName: 'Driver',
            field: 'driverNameSorting',
            cellRenderer: useCallback((props) => (
                props?.data?.fleet?.fleetName ? props?.data?.fleet?.fleetName : "-"
            ), []),
            minWidth: 180,
        },
        {
            headerName: 'Date',
            field: 'createdDateSorting',
            cellRenderer: useCallback((props) => (
                props?.data?.createdDate ? formatTimestampToWithoutTime(props?.data?.createdDate) : "-"
            ), []),
        },
        {
            headerName: 'Vehicle Type',
            field: 'vehicle',
            cellRenderer: useCallback((props) => (
                props?.data?.vehicle?.name ? <p style={{ textTransform: "uppercase" }}>{props?.data?.vehicle?.name}</p> : "-"
            ), []),
            sortable: false
        },
        {
            headerName: 'Planned Completion',
            field: 'plannedCompletion',
            cellRenderer: useCallback((props) => (
                props?.value ? formatTimestampToDate24Hours(props?.value) : "-"
            ), []),
            minWidth: 180,
            sortable: false
        },
        {
            headerName: 'Actual Completion',
            field: 'actualCompletion',
            cellRenderer: useCallback((props) => (
                props?.value ? formatTimestampToDate24Hours(props?.value) : "-"
            ), []),
            minWidth: 180,
            sortable: false
        },
        {
            headerName: 'Planned Duration',
            field: 'plannedDuration',
            cellRenderer: useCallback((props) => (
                formatTime(props?.value)
            ), []),
            minWidth: 180,
            sortable: false
        },
        {
            headerName: 'Actual Duration',
            field: 'actualDuration',
            suppressMenu: false,
            cellRenderer: useCallback((props) => (
                formatTime(props?.value)
            ), []),
            minWidth: 180,
            sortable: false
        },
        {
            headerName: 'Relative Difference',
            field: 'relativeDifference',
            suppressMenu: false,
            cellRenderer: (props) => {
                return <span className={props?.value?.sign === "-" ? "relativeDifferenceDark" : ""}>{formatTime(props?.value)}</span>
            },
            cellStyle: { textAlign: "right", justifyContent: "end" },
            headerClass: "right-aligned-cell-header",
            sortable: false
        },
    ]);

    const parseCsv = (csv) => {
        return new Promise((resolve, reject) => {
            Papa.parse(csv, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    resolve(result.data);
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                    reject(error);
                }
            });
        });
    };

    const handleExport = async (filters) => {
        const start = filters?.startDate == undefined ? routeFilter?.startDate : filters?.startDate == null ? "" : formatTimestampToMapOld(filters?.startDate)
        const end = filters?.endDate == undefined ? routeFilter?.endDate : filters?.endDate == null ? "" : formatTimestampToMapOld(filters?.endDate)

        try {
            const res = await dispatch(fetchRouteExport({
                data: {
                    startDate: formatTimestampToMapOld(filters?.startDate) !== "Invalid Date" ? start : "",
                    endDate: formatTimestampToMapOld(filters?.endDate) !== "Invalid Date" ? end : "",
                    // startDate: (filters?.startDate && filters?.endDate) && filters?.startDate ? start : "",
                    // endDate: (filters?.startDate && filters?.endDate) && filters?.endDate ? end : "",
                    fleetName: filters?.driverName ? filters?.driverName : "",
                }
            }
            )).unwrap()
            const parseData = await parseCsv(res)
            setCsv(parseData)
        } catch (error) {
            console.error('Error handling CSV export or parsing:', error);
        }
    }

    useEffect(() => {
        handleExport(routeFilter)
    }, [])

    const onRowClicked = useCallback((row) => {
        dispatch(openCustomDrawer({
            drawer: 'TASK_DETAIL_ROUTE',
            drawerData: row?.data?._id,
        }))
    }, []);

    return (
        <>
            <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={0} mb={3}>
                <Grid item xs={12} sm={2}>
                    <Typography variant='h4' className='text-24' color='#ffffff'>Route Analytics</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={1.5}>
                        <MDButton onClick={() => { handleExport() }} style={{ textTransform: "none", minWidth: "180px", borderRadius: "30px", minHeight: "40px" }}
                            startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
                            variant="contained" color="primary">
                            <CSVLink data={csv} style={{ color: "#ffffff", textTransform: "none" }}>
                                Export as .CSV
                            </CSVLink>
                        </MDButton>
                    </Stack>
                </Grid>
            </Grid>
            <div className="ag-theme-quartz-dark row-pointer">
                <AgGridTable
                    col={columnDefs}
                    mainGridApiRef={mainGridApiRef}
                    getRows={getRows}
                    pageRef={pageRef}
                    isLoading={tableloading}
                    style={{ height: "70vh" }}
                    gridOptions={{ rowHeight: 64 }}
                    onRowClicked={onRowClicked}
                    maxLimit={20}
                />
            </div>
        </>
    )
}

export default RouteAnalyticsTable;