import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchHyperwolfMemberOrdersByCuidAPI, fetchHyperwolfMembersAPI, fetchMemberByCuidAPI, fetchMemberByEmailAPI } from "../../../redux/apis/hyperwolf/members"


export const getHyperwolfMembers = createAsyncThunk(
    'hyperwolf/members',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchHyperwolfMembersAPI(filters)
            return res.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getMemberDetailsbyEmail = createAsyncThunk(
    'hyperwolf/memberDetails',
    async (email , { rejectWithValue }) => {
        try {
            const res = await fetchMemberByEmailAPI(email)
            return res.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchMemberOrdersByCuid = createAsyncThunk(
    'hyperwolf/memberOrders',
    async ( {cuid, filters}, { rejectWithValue }) => {
        try{
            const res = await fetchHyperwolfMemberOrdersByCuidAPI(cuid,filters)
            return res.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const hyperwolfMemberSlice = createSlice( {
    name: 'members',
    initialState:{
    members:[],
    memberDetails: {},
    memberOrderDetails:{},
    loading: false,
    error: null,
},
reducers: {
    // Define additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder.addCase(getHyperwolfMembers.fulfilled, (state, action) =>{
        state.loading = false;
        state.members = action.payload; // Update state with the received data
        state.memberDetails = {}
        state.error = null;
      });
  
      // Extra reducer for the rejected action of fetchMembers
      builder.addCase(getHyperwolfMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Update state with the error message
      });
  
      // Extra reducer for the pending action of fetchMembers
      builder.addCase(getHyperwolfMembers.pending, (state) => {
        state.loading = true;
      });

    builder.addCase(getMemberDetailsbyEmail.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload.memberDetail, 'tttttttt')
        state.memberDetails = action.payload.memberDetail; // Update state with the received details
        console.log(state.memberDetails, 'ttttt')
        state.error = null;
      });
  
      // Extra reducer for the rejected action of fetchMembersDetails
      builder.addCase(getMemberDetailsbyEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.memberDetail; // Update state with the error message
      });
  
      // Extra reducer for the pending action of fetchMembersDetails
      builder.addCase(getMemberDetailsbyEmail.pending, (state) => {
        state.loading = true;
      });

      builder.addCase(fetchMemberOrdersByCuid.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload, 'XXXXXX')
        state.memberOrderDetails.Details = action.payload; // Update state with the received details
        state.error = null;
      });
  
      // Extra reducer for the rejected action of fetchMembersDetails
      builder.addCase(fetchMemberOrdersByCuid.rejected, (state, action) => {
        state.memberOrderDetails.loading = false;
        state.error = action.payload; // Update state with the error message
      });
  
      // Extra reducer for the pending action of fetchMembersDetails
      builder.addCase(fetchMemberOrdersByCuid.pending, (state) => {
        state.loading = true;
      });

  } 

}
)

export const { /* Any additional actions */ } = hyperwolfMemberSlice.actions;

export default hyperwolfMemberSlice.reducer;
