import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, IconButton, Slide, Stack, Typography } from '@mui/material'
import { AgGridTable } from 'components/tables/AgGridTable'
import { useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { formatDate } from 'utilities/common'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../redux/slices/modalSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Search from 'examples/Search'
import { useDebounce } from 'hooks/useDebounce'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import StatusText from 'common/component/StatusText'
import { openSnackbar } from '../../../redux/slices/common'
import { formatTimestampTo12HourTime } from 'utilities/common'
import { getImageExtension } from 'utilities/common'
import { deleteBreak, exportBreaks, fetchBreaks } from '../../../redux/slices/hyperwolf/driver/breaks'
import { formatTimestampToTime } from 'utilities/common'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { CSVLink } from 'react-csv'
import Papa from 'papaparse';
import { formatTimestampToTimeWithTimezone } from 'utilities/common'
import dayjs from 'dayjs'


const uploadThumbnailImage = {
  borderRadius: "20px",
  width: "100px",
  height: "100px",
  objectFit: "cover",
  boxShadow: "0 0 1px rgb(0 0 0 / 20%)",
  marginRight: '10px'
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function Break() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [notesData, setNotesData] = useState("")
  const [csv, setCsv] = useState([])

  const handleSearch = useDebounce((term) => {
    pageRef.current = 0
    searchFn()
  }, 500);

  const { breaks = {}, loading = false } = useSelector(state => state.breaks)



  const resetData = () => {
    pageRef.current = 0
    if (mainGridApiRef.current) {
      mainGridApiRef.current.forEachNode((node) => {
        node.setSelected(false);
      });
    }

    searchFn()
    setNotesData("")
  }

  const deleteHandler = (deleteId) => {
    dispatch(setModalLoader(true))
    dispatch(deleteBreak(deleteId)).unwrap().then((res) => {
      showError(res?.data.message, 'success')
      dispatch(closeCustomModalDialog())
      resetData()
      pageRef.current = 0
      getRows(gridRefApi.current)
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showError(err?.message, 'error')
    })
  }

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }
  
  const handleShowMessage=() => {
    showError('CSV exported successfully', 'success')
  }

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Break Name',
      field: 'breakName',
      key: "breakName",
      suppressMenu: false,
      cellRenderer: (props) => {
        return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: "pointer", whiteSpace: "normal", textTransform: 'capitalize' }}>{props?.data?.breakName}</Typography>
      },
      minWidth: 240,
      sortable: false
    },
    {
      headerName: 'From Time',
      field: 'fromTime',
      suppressMenu: false,
      minWidth: 140,
      sortable: false
    },
    {
      headerName: 'To Time',
      field: 'toTime',
      suppressMenu: false,
      minWidth: 140,
      sortable: false
    },
    {
      headerName: 'Brake Duration Minutes',
      field: 'duration',
      suppressMenu: false,
      minWidth: 250,
      sortable: false
    },
    {
      headerName: 'Status',
      field: 'status',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <StatusText status={props?.data?.status} />
      },
      minWidth: 100,
      sortable: false
    },
    // {
    //   headerName: 'Send Break at',
    //   field: 'sendBreakAt',
    //   suppressMenu: false,
    //   minWidth: 120,
    //   sortable: false
    // },
    {
      headerName: 'Actions',
      cellRenderer: (props) => {
        return <Box display="inline-flex" alignItems="center">
          <IconButton onClick={(e) => {
            e.stopPropagation()
            onEditClicked(props)
          }} color='primary' size='small' variant="primary">
            <EditIcon width='16px' height='16px' />
          </IconButton>
          <IconButton onClick={(e) => {
            e.stopPropagation()
            dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?._id, deleteType: 'break' }, modal: 'DELETE_MODAL' }))
          }} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
            <DeleteIcon width='16px' height='16px' />
          </IconButton>
        </Box>
      },
      sortable: false,
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
      minWidth: 120,
      maxWidth: 100,
      pinned: 'right'
    },

  ]);

  function getSortInfo(data) {
    const result = {};
    data.forEach(item => {
      if (item?.colId === 'memberType') {
        result[item.colId] = item.sort === "asc" ? 'AdultUse' : "MedicinalUser";
      } else {
        result[item.colId] = item.sort === "asc";
      }

    });

    return result;
  }

  const getRows = async (params) => {
    gridRefApi.current = params;
    let filter = {}
    if (params?.sortModel.length) {
      filter = { ...getSortInfo(params?.sortModel) }
    }
    dispatch(fetchBreaks({ skip: pageRef.current, limit:20, searchTerm: searchTermRef.current, ...filter })).unwrap().then(res => {
      const { breaks, total } = res.data
      const breaksArray = breaks?.map((item => {
        const { timezone, fromTime, toTime, sendBreakAt } = item;
        const formatTime = (timeStr, tz) => {
          const [hours, minutes] = timeStr.split(':').map(Number);
          const utcDate = dayjs.utc().set('hour', hours).set('minute', minutes);
          const localDate = utcDate.tz(tz).local(); // Convert to local time
          return localDate.format('hh:mm A');
        };
        const date = item?.createdDate ? new Date(item?.createdDate) : ""
        return { ...item, 
          fromTime: formatTime(fromTime, timezone),
          toTime: formatTime(toTime, timezone),
          sendBreakAt: formatTime(sendBreakAt, timezone),joinedDate: date ? formatDate(date) : "-", id: item.memberId, address: `${item?.deliveryAddress?.address} ${item?.deliveryAddress?.city} ${item?.deliveryAddress?.state} ${item?.deliveryAddress?.zipcode}` }
      }))
      params.successCallback([...breaksArray], total);
      pageRef.current = pageRef.current + 20
    })
  }

  const searchFn = () => {
    const dataSource = {
      getRows
    }
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  }

  const onRowClicked = (row) => {
    // if (row?.colDef?.headerName !== 'Actions' && row?.colDef?.headerName !== 'Notes') {
    //   navigate(`/members/detail/${row?.data.memberId}`)
    // }
  }


  const onEditClicked = (row) => {
    navigate(`/hyperwolf/break/${row.data._id}`)
  }

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value
    handleSearch(value);

    // Debounce the search callback
  };

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay()
      } else if (breaks?.data.total == 0) {
        mainGridApiRef.current.showNoRowsOverlay()
      }
      else {
        mainGridApiRef.current.hideOverlay()
      }
    }

  }, [breaks, loading])

  const parseCsv = (csv) => {
    return new Promise((resolve, reject) => {
      Papa.parse(csv, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          // Resolve with the parsed data
          resolve(result.data);
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          reject(error);
        }
      });
    });
  };

  const handleBreakExport = async () => {
    try {
    const res = await dispatch(exportBreaks()).unwrap()
    // showError('CSV exported successfully', 'success')
      const parseData = await parseCsv(res?.data)
      setCsv(parseData)
    }  catch (error) {
      console.error('Error handling CSV export or parsing:', error);
  }
}

  useEffect(() => {
    handleBreakExport()
  },[])

  // const handleBreakExport = () => {
  //   try {
  //   dispatch(exportBreaks()).unwrap()
  //   .then(res => {
  //     console.log(res, 'rescsv')
  //     setCsv(res.data)
  //   })
  // } catch (error){
  //   console.error("error downloading csv", error)
  // }
  // }


  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>

        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={1} mb={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant='h4'>Breaks</Typography>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
              <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
              
              <MDButton
                startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
                variant="contained" color="primary"
                sx={{ minWidth: "165px", backgroundColor: "#656575", borderRadius: "30px" }}
                style={{ textTransform: "none", minHeight: "40px" }}
                onClick = {handleBreakExport}
              >
                <CSVLink data={csv} style={{ color: "#ffffff", textTransform: "none" }}>
                  Export as .CSV
                </CSVLink>

              </MDButton>

              <MDButton
                startIcon={<AddRoundedIcon fontSize='large' />}
                variant="contained" color="primary"
                onClick={() => navigate('/hyperwolf/break/create')}  
                sx={{ minWidth: "170px" }}
              >
                Add Break
              </MDButton>
            </Stack>
          </Grid>
        </Grid>

        <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} onRowClicked={onRowClicked} style={{ height: "58vh" }} />
      </Box>
    </CommonLayout>
  )
}
