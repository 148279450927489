import axiosClientHyperDrive from "axiosClient/indexHyperdrive";
import { getQueryString } from "utilities/common";

// Main Setting API
export function addStartTaskAPI(data) {    
    return axiosClientHyperDrive.post('api/v1/admin/tasks/create',data);
}

export async function fetchHyperwolfTasksRegionsAPI(filters) {
    return await axiosClientHyperDrive.get(`/api/v1/admin/tasks/start/list/?${getQueryString(filters)}`);
}

export async function updateTaskRegionInformationAPI(data,regionId) {
    return await axiosClientHyperDrive.put(`/api/v1/admin/tasks/start/update/${regionId}`,data);
}
