import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setLayout } from "../../../redux/slices/materialUISlice";
import { useDispatch, useSelector } from "react-redux";

// Images
import { Box, Button, Card, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { loginUser } from "../../../redux/slices/authSlice";
import { openSnackbar } from "../../../redux/slices/common";
import hyperwolfLogo from "assets/images/hyperwolf.svg";
import authImg from "assets/images/auth-bg.jpg";
import colors from "assets/theme/base/colors";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { requestPermission } from "firebaseInit";
import { getData } from "utilities/common";
import { HYPERWOLF } from "utilities/constants";
import { HYPERDRIVE } from "utilities/constants";

function Basic() {
  const { adminsMode = '' } = useSelector(state => state.common)
  const navigate = useNavigate()
  const [rememberMe, setRememberMe] = useState(false);
  const { logging = false } = useSelector(state => state.auth)
  // const { instance, accounts, inProgress } = useMsal();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({})



  const handleLogin = async () => {
    // if (adminsMode === HYPERWOLF) {
    //   dispatch(loginUser({ ...userData })).unwrap().then((res) => {
    //     let screenToLoad = JSON.parse(getData('adminsMode')) === HYPERWOLF ? '/manage-cannabinoids?cannabinoidTab=main_cannabinoid' : res?.userRoles?.includes('Sales Associate') || res?.userRoles?.includes('Customer Support') ? '/orders?orderFilter=' : res?.userRoles?.includes('Sales Associate') || res?.userRoles?.includes('Developer') ? '/dashboard' : '/products'
    //     navigate(screenToLoad)
    //     showError(res?.message, 'success')
    //   }).catch((err) => {
    //     showError(err?.message, 'error')
    //   })
    //   return;
    // }
    // if (adminsMode === HYPERDRIVE) {

    // }
    dispatch(loginUser({ ...userData, deviceToken: JSON.parse(getData('firebaseDeviceToken')) })).unwrap().then((res) => {
      let screenToLoad = JSON.parse(getData('adminsMode')) === HYPERDRIVE ? '/hyperdrive/home?roleTab=map' : JSON.parse(getData('adminsMode')) === HYPERWOLF ? '/manage-cannabinoids?cannabinoidTab=main_cannabinoid' : res?.userRoles?.includes('Sales Associate') || res?.userRoles?.includes('Customer Support') ? '/orders?orderFilter=' : res?.userRoles?.includes('Sales Associate') || res?.userRoles?.includes('Developer') ? '/dashboard' : '/products'
      console.log("screenToLoad", screenToLoad)
      navigate(screenToLoad)
      showError(res?.message, 'success')
    }).catch((err) => {
      showError(err?.message, 'error')
    })
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }


  // const isAuthenticated = useIsAuthenticated();
  // const handleLogout = () => {
  //   console.log(isAuthenticated, "isAuthenticated")
  //   // logout();
  //   if (isAuthenticated)
  //     instance.logoutRedirect()
  //   console.log('Logged out');
  // };

  // const handleGetToken = async () => {
  //   console.log(instance, accounts, inProgress, "accounts")

  // };
  const onChangeHandler = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setLayout("singup"))
  }, [pathname]);

  const { primary } = colors;


  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    requestPermission()
  }, [])

  return (
    <Grid className="auth-pg" alignItems="center" container>
      <Grid item xs={12} md={6} p={2.5} pt={{ xs: 8, md: 2.5 }}>
        <Stack textAlign="center" direction="column" alignItems="center" justifyContent="center" maxWidth="500px" mx="auto" height="100%">
          <img src={hyperwolfLogo} alt="HYPERWOLF" height="32px" />

          <Box textAlign="center" mt={6} mb={4}>
            <Typography variant="h2" fontWeight="semibold">Sign In</Typography>
            <Typography variant="body1" mt={1}>Welcome! Please Sign In to continue.</Typography>
          </Box>

          <TextField fullWidth sx={{ mb: 3 }} label='Enter Email' onChange={onChangeHandler} name="email" />
          <TextField fullWidth label="Enter Password" onChange={onChangeHandler} type={showPassword ? 'text' : 'password'} name="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="center">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="center"
                  >
                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box textAlign='end' width='100%'>
            <Typography mt={1} variant='h6' fontWeight="medium">
              <Link to="/forgot-password" style={{ color: primary.main }}>
                Forgot Password?
              </Link>
            </Typography>
          </Box>
          <MDButton onClick={handleLogin} sx={{ mt: 3, minWidth: "100%" }} disabled={logging} variant="contained" color="primary">Login</MDButton>

          {/* <Typography mt={2} variant='h5' fontWeight="medium">
            Don&lsquo;t have an account? &nbsp;
            <Link to='/signup' style={{color: primary.main}}>Sign Up</Link>
          </Typography> */}
        </Stack>
      </Grid>

      <Grid item xs={12} md={6} p={2.5}>
        <img src={authImg} alt="HYPERWOLF" className="auth-banner-img" />
      </Grid>
    </Grid>
  );
}

export default Basic;
