import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addTerpenoidAPI, addTerpenoidToProductAPI, createCannabinoidAPI, createCannabinoidBannersAPI, deleteCannabinoidAPI, deleteCannabinoidBannerAPI, deleteMainTerpenoidAPI, deleteTerpenoidAPI, fetchTerpenoidDetailsAPI, fetchTerpenoidsAPI, getCannabinoidBannersAPI, getCannabinoidsListAPI, getMainCannabinoidAPI, getSingleCannabinoidAPI, getSingleCannabinoidBannerAPI, updateCannabinoidAPI, updateMainCannabinoidAPI, updateSingleCannabinoidBannerAPI, updateTerpenoidAPI, updateTerpenoidToProductAPI } from "../../redux/apis/cannabinoids";
import { HEMP } from "utilities/constants";
const adminsMode = JSON.parse(localStorage.getItem('adminsMode'))
export const fetchTerpenoids = createAsyncThunk(
  'terpenoids/fetchTerpenoids',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchTerpenoidsAPI(data);
      return res
    } catch (error) {
      console.log("erro", error)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const createTerpenoid = createAsyncThunk(
  'terpenoids/createTerpenoid',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await addTerpenoidAPI(data);
      return res
    } catch (error) {
      console.log("erro", error)
      return rejectWithValue(error?.response?.data)
    }
  }
)


export const updateTerpenoid = createAsyncThunk(
  'terpenoids/updateTerpenoid',
  async ({ data, terpenoidId }, { rejectWithValue }) => {
    try {
      const res = await updateTerpenoidAPI(data, terpenoidId);
      return res
    } catch (error) {
      console.log("erro", error)
      return rejectWithValue(error?.response?.data)
    }
  }
)


export const deleteTerpenoid = createAsyncThunk(
  'terpenoids/deleteTerpenoid',
  async (data, { rejectWithValue }) => {
    try {
      const res = await deleteTerpenoidAPI(data);
      return res
    } catch (error) {
      console.log("erro", error)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const deleteMainTerpenoid = createAsyncThunk(
  'terpenoids/deleteMainTerpenoid',
  async (data, { rejectWithValue }) => {
    try {
      const res = await deleteMainTerpenoidAPI(data);
      return res
    } catch (error) {
      console.log("erro", error)
      return rejectWithValue(error?.response?.data)
    }
  }
)




export const fetchSingleTerpenoid = createAsyncThunk(
  'terpenoids/fetchSingleTerpenoid',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchTerpenoidDetailsAPI(data);
      return res
    } catch (error) {
      console.log("erro", error)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const assignTerpenoidToProduct = createAsyncThunk(
  'terpenoids/assignTerpenoidToProduct',
  async ({ data, productId }, { rejectWithValue }) => {
    try {
      const res = await addTerpenoidToProductAPI(data, productId);
      return res
    } catch (error) {
      console.log("erro", error)
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const updateTerpenoidToProduct = createAsyncThunk(
  'terpenoids/updateTerpenoidToProduct',
  async ({ data, productId }, { rejectWithValue }) => {
    try {
      const res = await updateTerpenoidToProductAPI(data, productId);
      return res
    } catch (error) {
      console.log("erro", error)
      return rejectWithValue(error?.response?.data)
    }
  }
)

// Cannabinoid

export const getMainCannabinoid = createAsyncThunk(
  'strain/getMainCannabinoid',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await getMainCannabinoidAPI(filters);
      return adminsMode === HEMP ? res : res?.data;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateMainCannabinoid = createAsyncThunk(
  'strain/updateMainCannabinoid',
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateMainCannabinoidAPI(data);
      return adminsMode === HEMP ? res : res?.data;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCannabinoidsList = createAsyncThunk(
  'strain/getCannabinoidsList',
  async (data, { rejectWithValue }) => {
    try {
      const res = await getCannabinoidsListAPI(data);
      return adminsMode === HEMP ? res : res?.data;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const createCannabinoid = createAsyncThunk(
  'strain/createCannabinoid',
  async (data, { rejectWithValue }) => {
    try {
      const res = await createCannabinoidAPI(data);
      return adminsMode === HEMP ? res : res?.data;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateCannabinoid = createAsyncThunk(
  'strain/updateCannabinoid',
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateCannabinoidAPI(data);
      return adminsMode === HEMP ? res : res?.data;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);


export const getSingleCannabinoid = createAsyncThunk(
  'strain/getSingleCannabinoid',
  async (id, { rejectWithValue }) => {
    try {
      const res = await getSingleCannabinoidAPI(id);
      return adminsMode === HEMP ? res : res?.data;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteCannabinoid = createAsyncThunk(
  'strain/deleteCannabinoid',
  async (id, { rejectWithValue }) => {
    try {
      const res = await deleteCannabinoidAPI(id);
      return adminsMode === HEMP ? res : res?.data;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);


export const getHomepageCannabinoidBanners = createAsyncThunk(
  'common/getHomepageCannabinoidBanners',
  async (data, { rejectWithValue }) => {
    try {
      const res = await getCannabinoidBannersAPI(data);
      return adminsMode === HEMP ? res : res?.data
    } catch (error) {
      console.log("error", error)
      return rejectWithValue(error.response?.data)
    }
  }
)

export const createCannabinoidBanners = createAsyncThunk(
  'common/createCannabinoidBanners',
  async (data, { rejectWithValue }) => {
    try {
      const res = await createCannabinoidBannersAPI(data);
      return adminsMode === HEMP ? res : res?.data
    } catch (error) {
      console.log("error", error)
      return rejectWithValue(error.response?.data)
    }
  }
)

export const updateCannabinoidBanners = createAsyncThunk(
  'common/updateCannabinoidBanners',
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateSingleCannabinoidBannerAPI(data);
      return adminsMode === HEMP ? res : res?.data
    } catch (error) {
      console.log("error", error)
      return rejectWithValue(error.response?.data)
    }
  }
)


export const getSingleCannabinoidBanner = createAsyncThunk(
  'strain/getSingleCannabinoidBanner',
  async (id, { rejectWithValue }) => {
    try {
      const res = await getSingleCannabinoidBannerAPI(id);
      return adminsMode === HEMP ? res : res?.data;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);


export const deleteCannabinoidBanner = createAsyncThunk(
  'strain/deleteCannabinoidBanner',
  async (id, { rejectWithValue }) => {
    try {
      const res = await deleteCannabinoidBannerAPI(id);
      return adminsMode === HEMP ? res : res?.data;
    } catch (error) {
      console.log("error444", error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);



const initialState = {
  isLoading: false, // or 'loading', 'succeeded', 'failed'
  error: null,
  isDeleting: false,
  terpenoidsList: [],
  isFetching: false,
  terpeneDetails: {},
  singleCannabinoid: {},
  singleCannabinoidBanner: {},
  cannabinoidBanners: {}
};

const cannabinoidSlice = createSlice({
  name: 'terpenoids',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTerpenoids.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTerpenoids.fulfilled, (state, action) => {
        state.isLoading = false
        state.terpenoidsList = action.payload;
        state.terpeneDetails = {};

      })
      .addCase(fetchTerpenoids.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload;
      });

    builder
      .addCase(createTerpenoid.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createTerpenoid.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createTerpenoid.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(deleteTerpenoid.pending, (state) => {
        state.isDeleting = true;
        state.error = null;
      })
      .addCase(deleteTerpenoid.fulfilled, (state, action) => {
        state.isDeleting = false;
        state.deletedTerpenoidId = action.payload;
      })
      .addCase(deleteTerpenoid.rejected, (state, action) => {
        state.isDeleting = false;
        state.error = action.payload;
      });

    builder
      .addCase(fetchSingleTerpenoid.pending, (state) => {
        state.isFetching = true;
        state.error = null;
        state.terpeneDetails = {};
      })
      .addCase(fetchSingleTerpenoid.fulfilled, (state, action) => {
        state.isFetching = false;
        state.terpeneDetails = action.payload.terpeneDetails;
      })
      .addCase(fetchSingleTerpenoid.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.payload;
      });

    builder
      .addCase(updateTerpenoid.pending, (state) => {
        // Set isLoading to true when the updateTerpenoid is pending
        state.isLoading = true;
      })
      .addCase(updateTerpenoid.fulfilled, (state, action) => {
        // Handle fulfilled state, action.payload contains the resolved value
        // Set isLoading back to false after completion
        state.isLoading = false;
      })
      .addCase(updateTerpenoid.rejected, (state, action) => {
        // Handle rejected state, action.payload contains the error value
        // Set isLoading back to false after completion
        state.isLoading = false;
      });

    builder
      .addCase(assignTerpenoidToProduct.pending, (state) => {
        // Set isLoading to true when the assignTerpenoidToProduct is pending
        state.isLoading = true;
      })
      .addCase(assignTerpenoidToProduct.fulfilled, (state, action) => {
        // Handle fulfilled state, action.payload contains the resolved value
        // Set isLoading back to false after completion
        state.isLoading = false;
      })
      .addCase(assignTerpenoidToProduct.rejected, (state, action) => {
        // Handle rejected state, action.payload contains the error value
        // Set isLoading back to false after completion
        state.isLoading = false;
      });
    builder
      .addCase(updateTerpenoidToProduct.pending, (state) => {
        // Set isLoading to true when the updateTerpenoidToProduct is pending
        state.isLoading = true;
      })
      .addCase(updateTerpenoidToProduct.fulfilled, (state, action) => {
        // Handle fulfilled state, action.payload contains the resolved value
        // Set isLoading back to false after completion
        state.isLoading = false;
      })
      .addCase(updateTerpenoidToProduct.rejected, (state, action) => {
        // Handle rejected state, action.payload contains the error value
        // Set isLoading back to false after completion
        state.isLoading = false;
      });

    // cannabinoidSlice

    builder
      .addCase(getMainCannabinoid.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMainCannabinoid.fulfilled, (state, action) => {
        state.isLoading = false
        state.mainCannabinoid = action.payload;
        state.terpeneDetails = {};

      })
      .addCase(getMainCannabinoid.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload;
      });

    builder
      .addCase(updateMainCannabinoid.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateMainCannabinoid.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateMainCannabinoid.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getCannabinoidsList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCannabinoidsList.fulfilled, (state, action) => {
        state.isLoading = false
        state.cannabinoidList = action.payload;
        state.terpeneDetails = {};

      })
      .addCase(getCannabinoidsList.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload;
      });

    builder
      .addCase(createCannabinoid.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createCannabinoid.fulfilled, (state, action) => {
        state.isLoading = false

      })
      .addCase(createCannabinoid.rejected, (state, action) => {
        state.isLoading = false
      });

    builder
      .addCase(updateCannabinoid.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCannabinoid.fulfilled, (state, action) => {
        state.isLoading = false

      })
      .addCase(updateCannabinoid.rejected, (state, action) => {
        state.isLoading = false
      });

    builder
      .addCase(deleteCannabinoid.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCannabinoid.fulfilled, (state, action) => {
        state.isLoading = false

      })
      .addCase(deleteCannabinoid.rejected, (state, action) => {
        state.isLoading = false
      });

    builder
      .addCase(getSingleCannabinoid.pending, (state) => {
        state.isLoading = true
        state.singleCannabinoid = {}
      })
      .addCase(getSingleCannabinoid.fulfilled, (state, action) => {
        state.isLoading = false
        state.singleCannabinoid = action.payload?.cannabinoidDetails

      })
      .addCase(getSingleCannabinoid.rejected, (state, action) => {
        state.isLoading = false
      });

    builder
      .addCase(getHomepageCannabinoidBanners.pending, (state) => {
        state.isLoading = true
        state.cannabinoidBanners = {}
        state.singleCannabinoidBanner = {}
      })
      .addCase(getHomepageCannabinoidBanners.fulfilled, (state, action) => {
        state.isLoading = false
        state.cannabinoidBanners = action.payload
      })
      .addCase(getHomepageCannabinoidBanners.rejected, (state, action) => {
        state.isLoading = false
      });


    builder
      .addCase(createCannabinoidBanners.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createCannabinoidBanners.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createCannabinoidBanners.rejected, (state, action) => {
        state.isLoading = false
      });

    builder
      .addCase(getSingleCannabinoidBanner.pending, (state) => {
        state.isLoading = true
        state.singleCannabinoidBanner = {}
      })
      .addCase(getSingleCannabinoidBanner.fulfilled, (state, action) => {
        state.isLoading = false
        state.singleCannabinoidBanner = action.payload?.cannabinoidDetails
      })
      .addCase(getSingleCannabinoidBanner.rejected, (state, action) => {
        state.isLoading = false
      });
    builder
      .addCase(updateCannabinoidBanners.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCannabinoidBanners.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateCannabinoidBanners.rejected, (state, action) => {
        state.isLoading = false
      });

    builder
      .addCase(deleteCannabinoidBanner.pending, (state) => {
        state.isLoading = true
        state.singleCannabinoidBanner = {}
      })
      .addCase(deleteCannabinoidBanner.fulfilled, (state, action) => {
        state.isLoading = false
        state.singleCannabinoidBanner = action.payload?.cannabinoidDetails
      })
      .addCase(deleteCannabinoidBanner.rejected, (state, action) => {
        state.isLoading = false
      });
  },
});


export default cannabinoidSlice.reducer;