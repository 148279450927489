import React, { useEffect, useState } from 'react'
import { Card, Grid, Icon, Typography, Stack, TextField } from '@mui/material'
import MDButton from 'components/MDButton'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { openSnackbar } from '../../../../redux/slices/common';
import { addAnnouncement, editAnnouncement, getAnnouncementDetails } from '../../../../redux/slices/hyperdrive/setting';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function Announcements() {
    const [searchParams] = useSearchParams();
    const roleTab = searchParams.get('roleTab');
    const isEditAnnouncement = roleTab?.includes("edit")
    const announcementId = roleTab ? roleTab.split('/').pop().split('=').pop() : null;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [errors, setErrors] = useState({})
    const { mainGetAnnouncementsData, loading: loading = false } = useSelector(state => state?.commonHyperdrive)

    const [announcement, setAnnouncement] = useState({
        announcement: ""
    })

    const reasonChangeHandler = (event) => {
        setAnnouncement({ [event?.target?.name]: event?.target.value })
    }
    
    const validateAnnouncement = () => {
        let isError = false
        let errors = {}
        if (!announcement?.announcement?.trim()) {
            isError = true
            errors = { ...errors, announcement: 'Announcement is required' }
        }
        return { errors, isError }
    }

    const submitHandler = () => {
        const { isError, errors } = validateAnnouncement()
        setErrors(errors)
        if (isError) return;
        setErrors({})
        if (isEditAnnouncement) {
            dispatch(editAnnouncement({ data: announcement, announcementId }))
                .unwrap()
                .then(res => {
                    navigate(-1)
                    showResponseMessage(res?.message, 'success');
                })
                .catch(err => {
                    showResponseMessage(err?.message, 'error');
                });
        } else {
            dispatch(addAnnouncement({ data: announcement }))
                .unwrap()
                .then(res => {
                    navigate(-1)
                    showResponseMessage(res?.message, 'success');
                })
                .catch(err => {
                    showResponseMessage(err?.message, 'error');
                });
        }
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    useEffect(() => {
        if (isEditAnnouncement) {
            dispatch(getAnnouncementDetails(announcementId))
        }
    }, [isEditAnnouncement])

    useEffect(() => {
        if (mainGetAnnouncementsData && isEditAnnouncement) {
            setAnnouncement({ announcement: mainGetAnnouncementsData?.data?.announcement ? mainGetAnnouncementsData?.data?.announcement : "" })
        }
    }, [mainGetAnnouncementsData])
    console.log("mainGetAnnouncementsData",mainGetAnnouncementsData);
    

    return (
        <Card sx={{ py: 3, px: 4, mt: 2, mb: 4, borderRadius: 0 }}>

            <Stack direction={{ xs: "column", sm: "row" }} alignItems={{ sm: "center" }} justifyContent="space-between" gap={2} mb={4.5}>
                <Typography variant='h5' fontWeight="semibold" display="flex" alignItems="center" color="#9A9AAF" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                    <Icon className="mr-1 cur-pointer">chevron_left</Icon>Announcements
                </Typography>
            </Stack>

            <Grid container alignItems="center" spacing={{ xs: 3, md: 3 }} mb={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        autoComplete="off"
                        fullWidth
                        helperText={errors.announcement}
                        error={errors?.announcement}
                        label={!isEditAnnouncement ? "Add Announcement" : "Edit Announcement"}
                        name="announcement"
                        onChange={(e) => reasonChangeHandler(e)}
                        className="form-input"
                        value={announcement?.announcement || ''}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25} mb={2} mt={2}>
                        <MDButton sx={{ minWidth: "140px" }} type='button' variant='contained' color='primary' onClick={() => submitHandler()} disabled={loading} loading={loading}>
                            Save
                        </MDButton>
                        <MDButton sx={{ minWidth: "140px" }} type='button' variant='outlined' color='dark' className="dark-outlined-btn" onClick={() => navigate(-1)}>
                            Cancel
                        </MDButton>
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    )
}