// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import PropTypes from 'prop-types';
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Box, Divider } from "@mui/material";
import { useLocation } from "react-router-dom/dist";
import { useMemo } from "react";
import { getData } from "utilities/common";


function CommonLayout({ children }) {
    const { pathname } = useLocation()
    const adminsMode = JSON.parse(getData('adminsMode'))
    const showSidebar = useMemo(() => {
        return pathname.includes('hyperdrive') ? false : true
    }, [pathname])

    return (
        <>           
            {
                showSidebar ? <>
                    <DashboardNavbar />
                    <DashboardLayout>
                        {/* <Divider
                            sx={{
                                marginBottom: 0,
                                paddingBottom: 0
                            }}
                        /> */}
                        <Box className="main-content-area">
                            {children}
                        </Box>
                        <Footer />
                    </DashboardLayout>
                </> : <>
                    <Box className={`main-content-area ${adminsMode ? 'dark-mode' : ''}`}>
                    <DashboardNavbar />
                        {children}
                    </Box>
                    {/* <Footer /> */}
                </>
            }
        </>
    );
}

CommonLayout.propTypes = {
    children: PropTypes.node.isRequired,
};
export default CommonLayout;