import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import NoDataFound from 'common/component/NoDataFound';
// import 'ag-grid-enterprise';

function LoadingOverlay() {
  return (
    <div className="ag-overlay-loading-top">
      <div>Loading...</div>
    </div>
  );
}


const filterParams = { values: [] };

export const sortAndFilter = (allOfTheData, sortModel, filterModel) => {
  return sortData(sortModel, filterData(filterModel, allOfTheData));
};

const sortData = (sortModel, data) => {
  const sortPresent = sortModel && sortModel.length > 0;
  if (!sortPresent) {
    return data;
  }
  // do an in memory sort of the data, across all the fields
  const resultOfSort = data.slice();
  resultOfSort.sort(function (a, b) {
    for (let k = 0; k < sortModel.length; k++) {
      const sortColModel = sortModel[k];
      const valueA = a[sortColModel.colId];
      const valueB = b[sortColModel.colId];
      // this filter didn't find a difference, move onto the next one
      if (valueA == valueB) {
        continue;
      }
      const sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
      if (valueA > valueB) {
        return sortDirection;
      } else {
        return sortDirection * -1;
      }
    }
    // no filters found a difference
    return 0;
  });
  return resultOfSort;
};

const filterData = (filterModel, data) => {
  const filterPresent = filterModel && Object.keys(filterModel).length > 0;
  if (!filterPresent) {
    return data;
  }
  const resultOfFilter = [];
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (filterModel.age) {
      const age = item.age;
      const allowedAge = parseInt(filterModel.age.filter);
      // EQUALS = 1;
      // LESS_THAN = 2;
      // GREATER_THAN = 3;
      if (filterModel.age.type == 'equals') {
        if (age !== allowedAge) {
          continue;
        }
      } else if (filterModel.age.type == 'lessThan') {
        if (age >= allowedAge) {
          continue;
        }
      } else {
        if (age <= allowedAge) {
          continue;
        }
      }
    }
    if (filterModel.year) {
      if (filterModel.year.values.indexOf(item.year.toString()) < 0) {
        // year didn't match, so skip this record
        continue;
      }
    }
    if (filterModel.country) {
      if (filterModel.country.values.indexOf(item.country) < 0) {
        continue;
      }
    }
    resultOfFilter.push(item);
  }
  return resultOfFilter;
};

export const AgGridTable = ({ col = [], getRows = () => { }, pageRef = null, mainGridApiRef = null, onRowClicked = () => { }, gridOptions = {}, isLoading = false, style = {}, onSelectionChanged = () => { }, maxLimit = 20, rowClassRules = {} }) => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '70vh' }), []);
  const gridStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridApi = useRef()
  const gridRef = useRef();
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      // filter:true,
      // sorting:true,
      floatingFilter: false,
      // defaultValue: '-',

    };
  }, []);
  const getRowId = useCallback(function (params) {
    return params.data.id;
  }, []);

  const onRowGroupOpened = useCallback((event) => {
    var rowNodeIndex = event.node.rowIndex;
    console.log("event", event)
    // factor in child nodes so we can scroll to correct position
    var childCount = event.node.childrenAfterSort
      ? event.node.childrenAfterSort.length
      : 0;
    var newIndex = rowNodeIndex + childCount;
    gridRef.current.api.ensureIndexVisible(newIndex);
  }, []);

  const onGridReady = useCallback(async (params) => {
    gridApi.current = params
    if (mainGridApiRef) {
      mainGridApiRef.current = params.api;
    }
    const dataSource = {
      rowCount: undefined,
      getRows
    }
    params.api.setGridOption('datasource', dataSource);
  }, []);

  // useEffect(() => {
  //   if (isLoading && gridApi.current?.api) {
  //     gridApi.current?.api.showLoadingOverlay()
  //   }
  //   if (!isLoading && gridApi.current?.api && gridApi?.current?.api?.getModel().getRowCount() > 0) {
  //     setTimeout(() => {
  //       gridApi.current?.api.hideOverlay()
  //     }, 500)
  //   }
  //   // if (!isLoading && gridApi?.current?.api?.getModel().getRowCount() === 0) {
  //   //   gridApi.current?.api.showNoRowsOverlay()
  //   // }
  // }, [isLoading, gridApi.current])

  useEffect(() => {
    if (isLoading && gridApi.current?.api) {
      gridApi.current?.api.showLoadingOverlay()
    }
    // if (!isLoading && gridApi?.current?.api?.getModel().getRowCount() === 0) {
    //   gridApi.current?.api.showNoRowsOverlay()
    // }
  }, [isLoading, gridApi.current])

  return (
    <div style={{ ...containerStyle, ...style }}>
      <div
        style={gridStyle}
        className={
          "ag-theme-quartz"
        }
      >
        <AgGridReact
          className='ag-grid--table'
          ref={gridRef}
          columnDefs={col}
          defaultColDef={{
            ...defaultColDef,
            lockPinned: true,
            lockPosition: true,
            resizable: false
          }}
          rowSelection={'multiple'}
          rowModelType={'infinite'}
          cacheBlockSize={maxLimit}
          getRowId={getRowId}
          onGridReady={onGridReady}
          onSortChanged={() => {
            pageRef.current = 0
          }}
          // gridOptions={{
          //   ...gridOptions, loadingOverlayComponent: 'customLoadingOverlay', // Specify the custom overlay component
          //   loadingOverlayComponentParams: {
          //     // Parameters for the custom overlay component
          //     loadingMessage: 'Loading...', // Custom loading message
          //   },
          //   overlayNoRowsTemplate: '<span style="padding: 10px;">No data found</span>',
          //   headerHeight: 38,
          //   rowHeight: 72,
          //   rowStyle: { marginTop: "8px" },
          // }}
          rowBuffer={0}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={1000}
          maxBlocksInCache={10}
          overlayLoadingTemplate={'<span class="loader"></span>'}
          overlayNoRowsTemplate='<span style="color: #9A9AAF;">No Data Found</span>'
          suppressRowClickSelection={true}
          noRowsOverlayComponent={() => <NoDataFound />}
          gridOptions={{
            onCellClicked: (params) => {
              console.log("params", params)
            },
            noRowsOverlayComponent: 'customOverlayComponent',
            headerHeight: 38,
            rowHeight: 64,
            // rowStyle: { marginBottom: "6px" },

            ...gridOptions,
          }}
          onCellClicked={onRowClicked}
          onCellValueChanged={() => {
            console.log("helel")
          }}
          rowClassRules={rowClassRules}
          onSelectionChanged={
            onSelectionChanged

          }
        />
        {/* {
          gridApi.current && gridApi?.current?.api?.getModel().getRowCount() === 0 && <div
            className="ag-template"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.85)',
              border: '1px solid #ccc',
              borderRadius: '5px',
              padding: '10px',
            }}
          >
            Not Data found
          </div>
        } */}

      </div>
    </div>
  );
};