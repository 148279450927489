import { Box, Grid, Typography, Card, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getDriverAnalyticsInfo } from '../../../../redux/slices/hyperdrive/driverAnalytics'
import { useSelector } from 'react-redux'
import SkeletonText from 'common/component/SkeletonText'

const DriverInfo = ({ driverId }) => {
  const dispatch = useDispatch()
  const { driverDetails } = useSelector(state => state?.driverAnalyticsHyperDrive)
  const [loading, setLoading] = useState(false)
  const driverInfo = driverDetails?.data?.driverInfo
  useEffect(() => {
    setLoading(true)
    dispatch(getDriverAnalyticsInfo({ driverId, data: {} })).unwrap().then(res => { setLoading(false) })
  }, [])

  return (
    <Card sx={{ padding: 3, backgroundColor: '#1E1E24', mt: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant='h4' className='text-20 text-white-700'>Driver Info</Typography>
        {
          !loading ?
            <Box display="flex" alignItems="center" className="duty-color">
              <Typography color={driverInfo?.fleetOnDutyStatus ? "#24CA49" : "#f8584b"} variant='caption' fontWeight="regular" ml={.5} textTransform="capitalize" className='d-flex align-item-center gap-2 white-space-nowrap'>
                <span className={driverInfo?.fleetOnDutyStatus ? "dot-duty on-duty" : 'dot-duty off-duty'}></span>
                {driverInfo?.fleetOnDutyStatus ? "On Duty" : "Off Duty"}
              </Typography>
            </Box>
            :
            <SkeletonText />
        }
      </Box>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Driver ID</Typography>
          {!loading ? <Typography variant='h5' className='text-black-500'>{driverInfo?.fleetDisplayId ? driverInfo?.fleetDisplayId : "-"}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Driver Name</Typography>
          {!loading ? <Typography variant='h5' className='text-black-500'>{driverInfo?.fleetName ? driverInfo?.fleetName : "-"}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Vehicle Info</Typography>
          {!loading ?
            <>
              <Typography variant='h5' className='text-black-500'>
                {driverInfo?.fleetTransportation?.name}
                {driverInfo?.fleetTransportation?.name && (driverInfo?.fleetVehicleDetails?.model || driverInfo?.fleetVehicleDetails?.color) ? ', ' : ''}
                {driverInfo?.fleetVehicleDetails?.model}
                {driverInfo?.fleetVehicleDetails?.model && driverInfo?.fleetVehicleDetails?.color ? ', ' : ''}
                {driverInfo?.fleetVehicleDetails?.color}
              </Typography>
            </>
            // <Typography variant='h5' className='text-black-500'>{driverInfo?.fleetTransportation?.name}, {driverInfo?.fleetVehicleDetails?.model}, {driverInfo?.fleetVehicleDetails?.color}</Typography>
            : <SkeletonText />}</Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Check In</Typography>
          {!loading ? <Typography variant='h5' className='text-black-500'>{driverInfo?.checkIn ? driverInfo?.checkIn : "-"}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Check Out</Typography>
          {!loading ? <Typography variant='h5' className='text-black-500'>{driverInfo?.checkOut ? driverInfo?.checkOut : "-"}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Drawer Starting Cash</Typography>
          {!loading ? <Typography variant='h5' className='text-black-500'>{driverInfo?.drawerStartingCash ? `$${driverInfo?.drawerStartingCash}` : "0"}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Today Tasks</Typography>
          {!loading ? <Typography variant='h5' className='text-black-500'>{driverInfo?.todayTaskCount ? driverInfo?.todayTaskCount : "0"}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Scheduled Tasks</Typography>
          {!loading ? <Typography variant='h5' className='text-black-500'>{driverInfo?.scheduledTask ? driverInfo?.scheduledTask : "0"}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Total Break Taken</Typography>
          {!loading ? <Typography variant='h5' className='text-black-500'>{driverInfo?.totalBreakDown ? driverInfo?.totalBreakDown : "0"}</Typography> : <SkeletonText />}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography className='text-black-200 text-14 mb-1'>Total Miles</Typography>
          {!loading ? <Typography variant='h5' className='text-black-500'>{driverInfo?.totalMiles ? driverInfo?.totalMiles : "0"}</Typography> : <SkeletonText />}
        </Grid>
      </Grid>
    </Card>
  )
}

export default DriverInfo