import { Close } from '@mui/icons-material'
import { Box, Button, FormHelperText, Grid, Stack, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import GoogleSuggestion from 'components/googleMaps/GoogleSuggestion'
import moment from 'moment'
import MapDriveListDrawer from './MapDriveListDrawer';
import { addStartTask } from '../../../redux/slices/hyperdrive/createTask';
import { openSnackbar } from '../../../redux/slices/common';
import ReassignDriverIcon from 'common/customIcons/reassigndriver'
import { useSelector } from 'react-redux'
import MDButton from 'components/MDButton'
import "react-datepicker/dist/react-datepicker.css";
import { customApiSwitch } from '../../../redux/slices/modalSlice'
import { useSearchParams } from 'react-router-dom'

export default function ReturnToHQ({ closeModal }) {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const { loading: loading = false } = useSelector(state => state?.taskHyperDrive)
    const addressError = useRef(false)
    const [formData, setFormData] = useState({
        // task_id: "",
        address: "",
        taskAssignmentMode: "driver",
        taskType: "returnToHeadquarterTask",
        fleetIds: [],
        taskDescription: "",
    })
    const [searchParams, setSearchParams] = useSearchParams();
    let roleTab = searchParams.get('roleTab')
    const [timeRange, setTimeRange] = useState({
        taskStartTime: null,
        taskEndTime: null,
    })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showManualList, setShowManualList] = useState(false);
    const today = new Date();

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        // setAnchorEl(event.currentTarget);
        setShowManualList(true)
    };

    const startRangeHandler = (event, name) => {
        const formatDateString = (event) => {
            const date = moment(event); // Parse the event date
            if (!date.isValid()) {
                return ''; // Return empty if date is invalid
            }
            return date.format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // Format the date
        };

        // Update the time range state based on the event
        setTimeRange(prevData => {
            // Create a new data object with updated date
            const newData = {
                ...prevData,
                [name]: formatDateString(event) // Set the new date based on the field name
            };

            const startDate = moment(newData.taskStartTime); // Parse the start time
            const endDate = moment(newData.taskEndTime); // Parse the end time
            console.log("startDate", startDate, newData.taskStartTime);

            // Validate start time when it is being set
            if (name === 'taskStartTime' && newData.taskEndTime) {
                // Check if end date is before start date
                if (endDate.isBefore(startDate)) {
                    setErrors(prevData => ({ ...prevData, taskStartTime: "Start date must be before the end date." })); // Set error
                    return prevData; // Return previous data without updates
                }
                // Check if start and end date are the same
                else if (endDate.isSame(startDate)) {
                    setErrors(prevData => ({ ...prevData, taskStartTime: "Start date and end date cannot be the same." })); // Set error
                    return prevData; // Return previous data without updates
                }
            }

            // Validate end time when it is being set
            if (name === 'taskEndTime' && newData.taskStartTime) {
                // Check if end date is before start date
                if (endDate.isBefore(startDate)) {
                    setErrors(prevData => ({ ...prevData, taskEndTime: "End date must be after the start date." })); // Set error
                    return prevData; // Return previous data without updates
                }
                // Check if start and end date are the same
                // else if (endDate.isSame(startDate)) {
                //     setErrors(prevData => ({ ...prevData, taskEndTime: "Start date and end date cannot be the same." })); // Set error
                //     return prevData; // Return previous data without updates
                // }
            }
            // Clear any previous error messages if validation passes
            setErrors({ taskStartTime: "", taskEndTime: "" });
            return newData; // Return the updated time range
        });
    };

    const startTaskHandler = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => {
            const newData = {
                ...prevData,
                [name]: value
            };
            if (name === 'taskAssignmentMode') {
                if (value === "manual") {
                    setShowManualList(true)
                }
                else {
                    setErrors({ fleetIds: "" })
                    setShowManualList(false)
                }
            }
            return newData;
        });
    };

    const validateTask = () => {
        let isError = false
        let errors = {}
        // if (timeRange?.taskStartTime == null) {
        //     isError = true
        //     errors = { ...errors, taskStartTime: 'Start Date is required' }
        // }
        if (!formData?.taskDescription?.trim()) {
            isError = true
            errors = { ...errors, taskDescription: 'Task Description is required' }
        }
        // if (timeRange?.taskEndTime == null) {
        //     isError = true
        //     errors = { ...errors, taskEndTime: 'End Date is required' }
        // }
        if (formData?.address == "" || formData?.address?.address == "") {
            isError = true
            errors = { ...errors, address: 'Address is required' }
        }

        return { errors, isError }
    }

    const submitHandler = () => {
        const startDate = moment(timeRange.taskStartTime); // Parse the start time
        const endDate = moment(timeRange.taskEndTime); // Parse the end time

        const validationResult = validateTask();
        let isError = validationResult.isError;
        let errors = { ...validationResult.errors };
        setErrors(errors);
        if (isError) return;

        if (formData?.taskAssignmentMode === "driver") {
            if (formData?.fleetIds?.length == 0) {
                isError = true;
                errors = { ...errors, fleetIds: 'Please select Driver' };
                setErrors(errors);
                return false
            }
        }
        // if (endDate.isSame(startDate)) {
        //     setErrors(prevData => ({ ...prevData, taskEndTime: "Start date and end date cannot be the same." })); // Set error
        //     return false; // Return previous data without updates
        // }

        // else if (formData?.taskAssignmentMode === "region") {
        //     if (formData?.regionIds?.length == 0) {
        //         isError = true;
        //         errors = { ...errors, regionIds: 'Region ID is required' };
        //         setErrors(errors);
        //         return false
        //     }
        // }

        const payload = { ...formData }
        // const payload = { ...formData, ...timeRange }
        dispatch(addStartTask({ data: payload }))
            .unwrap()
            .then(res => {
                dispatch(customApiSwitch({
                    dataSwitch: 'API_DATA_ROUTE',
                    routeData: roleTab
                }))
                showResponseMessage(res?.message, 'success');
                closeModal()
            })
            .catch(err => {
                showResponseMessage(err?.message, 'error');
            });

    }
    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const googleAddressErrorHandler = (status = false) => {
        addressError.current = status
        if (status) {
            setErrors({ ...errors, address: 'Address is required' })
        }
        else {
            setErrors(prevErrors => ({ ...prevErrors, address: '' }));
        }
    }

    function handleDataFromChild() {
        setShowManualList(false)
    }

    const handleFleetValueChange = (value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            fleetIds: value
        }));
        setErrors({ ...errors, fleetIds: "" });
    };

    const handleAddressChange = (addressed) => {
        const address = {
            address1: addressed?.address || "",
            city: addressed?.city || "",
            state: addressed?.state || "",
            zipcode: addressed?.zipcode || "",
            country: addressed?.country || "USA",
            latitude: addressed?.lat ? addressed.lat.toFixed(8) : "",
            longitude: addressed?.long ? addressed.long.toFixed(8) : "",

        };
        setFormData({ ...formData, address });
    }

    return (
        <Box className="cus-wrapper-drawer">
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} style={{ borderBottom: "1px solid  #1E1E24" }} className="drawer-head-sec">
                <Typography className="text-14 text-white-700">Create Return to HQ Task</Typography>
                <Close onClick={closeModal} style={{ cursor: "pointer", color: "#ffffff" }} />
            </Box>
            <Box className="drawer-scroll-sec">
                <Box px={2} py={4}>
                <Stack direction='column' mb={4}>
                        <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Task Info</Typography>
                        <Stack direction='column' gap={2} mb={1} className='dark-field'>
                            <TextField
                                autoComplete="off"
                                fullWidth
                                helperText={errors.taskDescription}
                                error={errors?.taskDescription}
                                label={'Task Description'}
                                name="taskDescription"
                                onChange={(e) => startTaskHandler(e)}
                                className="form-input"
                                value={formData?.taskDescription || ''}
                            />
                        </Stack>
                    </Stack>
                    {/* <Stack direction='column' mb={4}> */}
                        {/* <Stack direction='column' gap={2} mb={1} className='dark-field'>
                            <TextField
                                fullWidth
                                helperText={errors.task_id}
                                error={errors?.task_id}
                                label={'Task Id'}
                                name="task_id"
                                onChange={(e) => startTaskHandler(e)}
                                className="form-input"
                                value={formData?.task_id || ''}
                            />
                        </Stack> */}
                    {/* </Stack> */}
                    <Stack direction='column' mb={4}>
                        <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Headquarters Address</Typography>
                        <Stack direction='column' gap={2} mb={1} className='dark-field hd-google-suggestions'>
                            <GoogleSuggestion addressValue={formData?.address?.address} googleAddressErrorHandler={googleAddressErrorHandler}
                                onClearAddress={(address) => setFormData({ ...formData, address })}
                                addressChangeHandler={(address) => handleAddressChange(address)} />
                            {errors?.address && <FormHelperText error>{errors?.address}</FormHelperText>}
                        </Stack>
                    </Stack>
                    {/* <Stack direction='column' mb={4}>
                        <Typography fontWeight={600} className='text-20 text-white-700'>Time Window</Typography>
                        <Typography mb={2} fontWeight={400} className='text-16 text-black-600'>For tasks with a schedule or estimated fulfilment time.</Typography>
                        <Grid container spacing={2} className='drawer-date-field dark-field dark-calendar'>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <DateTask selected={timeRange?.taskStartTime} onChange={startRangeHandler} name={"taskStartTime"} placeholderText={"Start"} minDate={today} />
                                {
                                    errors?.taskStartTime && <span className='error_class'>{errors?.taskStartTime}</span>
                                }
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} className='cal-end'>
                                <DateTask selected={timeRange?.taskEndTime} onChange={startRangeHandler} name={"taskEndTime"} placeholderText={"End"} minDate={today} />
                                {
                                    errors?.taskEndTime && <span className='error_class'>{errors?.taskEndTime}</span>
                                }
                            </Grid>
                        </Grid>
                    </Stack> */}
                    <Stack>
                        <div className='d-flex reassign-driver-btn'>
                            <Button
                                aria-controls={open ? '' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                className='d-flex gap-2 text-white-900'
                            >
                                <ReassignDriverIcon />
                                <Typography fontWeight="semibold" className='text-12 text-white-900 text-transform-none'>Assign Driver</Typography>
                                {/* <KeyboardArrowDownIcon /> */}
                                <p className='button-error text-left'>{errors?.fleetIds}</p>
                            </Button>
                            {/* <Menu
                                id=""
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{ className: "hd-dropdown reassign-driver-dropdown" }}
                            >
                                <FormControl>
                                    <RadioGroup
                                        value={formData?.taskAssignmentMode}
                                        aria-labelledby=""
                                        defaultValue="auto"
                                        name="taskAssignmentMode"
                                        className='radio-colors'
                                        onChange={(e) => startTaskHandler(e)}
                                    >
                                        <FormControlLabel className='field-off-duty' value="manual" control={<Radio />} label="Manual" />
                                        <FormControlLabel className='field-idle' value="auto" control={<Radio />} label="Automatic" />                                </RadioGroup>
                                </FormControl>
                            </Menu> */}
                        </div>
                    </Stack>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} gap={2} style={{ borderTop: "1px solid  #1E1E24" }} className="drawer-bottom-btns">
                <Button variant="outlined" color="secondary" className='d-flex align-item-center gap-2' onClick={closeModal}>
                    Cancel
                </Button>
                <MDButton variant="contained" color="primary" className='d-flex align-item-center gap-2 green-btn' onClick={() => submitHandler()} disabled={loading} loading={loading}>
                    Create Task
                </MDButton>
            </Box>

            {showManualList &&
                <MapDriveListDrawer sendDataToParent={handleDataFromChild} onValueChange={handleFleetValueChange} breakTask={"hqTask"} />
            }
        </Box>
    )
}